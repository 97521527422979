<template>
    <el-date-picker format="MM/dd/yyyy" v-model="selectedDateUpdate" @change="emitDate" type="date" :size="size" :placeholder="placeholder" :picker-options="pickerOptions"></el-date-picker>
</template>

<script>
import moment from 'moment-timezone';
export default {
  name: 'DatePicker',
  props: {
    selectedDate: {
      type: String | Date,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: ''
    },
    disableTimeZoneConversion: {
      type: Boolean,
      default: false
    },
    disabledDates: {
      type: Function,
      default: null
    },
    module: {
      type: String,
      default: null
    },
    timeZone: {
      type: String,
      default: ''
    }
  },

  watch: {
    selectedDate(date) {
      this.$nextTick(() => {
        if (this.disableTimeZoneConversion) {
            this.selectedDateUpdate = moment(this.selectedDate).format('MM/DD/YYYY');
        }else {
          this.selectedDateUpdate = this.parseLocalDate(date)
        }
      });
    }
  },

  created(){
    if(!this.selectedDate) return 
    let dateEmited = '';
    if (this.disableTimeZoneConversion) {
      this.selectedDateUpdate = moment(this.selectedDate).format('MM/DD/YYYY');
      dateEmited = this.selectedDateUpdate
    } else {
      let onlyDate;
      if (!this.validateUtc(this.selectedDate)) {
        const initialSelectDate = moment(this.selectedDate).format('MM/DD/YYYY');
        onlyDate = this.getDateDifferenceMinutesTenantTimezone(initialSelectDate, this.timeZone)
      } else {
        onlyDate = this.selectedDate;
      }
      dateEmited = onlyDate
      const parseDate = this.parseLocalDate(onlyDate);
      this.selectedDateUpdate = parseDate;
    }
    
    if (this.module) return;
    this.$emit('date-selected', dateEmited)
  },

  data() {
    return {
      selectedDateUpdate: ''
    };
  },

  computed: {
    pickerOptions() {
      // Conditionally set disabledDate based on the prop
      if (this.disabledDates) return { disabledDate: this.disabledDates };
      return {};
    }
  },

  methods: {

    emitDate() {
      let data
      if (!this.disableTimeZoneConversion) {
          data = this.getDateDifferenceMinutesTenantTimezone(this.selectedDateUpdate, this.timeZone)
      } else {
         data = moment(this.selectedDateUpdate).format('YYYY-MM-DD');
      }
      const dateSelected = data ?? null
      this.$emit('date-selected', dateSelected)
    },
    validateUtc(date) {
      const dateString = moment(date).toISOString()
      const splitDate = dateString.split('T')
      const utcTenant = moment.tz(this.timeZone || this.getTenantTimeZone()).utcOffset() / 60;
      const absUtc = Math.abs(utcTenant)
      const comparaTo = `0${absUtc}:00:00.000Z`
      const isEqualUTC = splitDate[1] === comparaTo
      return isEqualUTC
    },
    parseLocalDate(onlyDate) {
      const utcTenant = moment.tz(this.timeZone || this.getTenantTimeZone()).utcOffset() / 60;
      const utcLocal = moment().utcOffset() / 60;
      const calculateDiffUtc = utcTenant - utcLocal
      const parseDate = moment(onlyDate).add(calculateDiffUtc, 'hour').toISOString();
      return parseDate
    }
    
  }
};
</script>