<template>
    <div class="mt-4 roster-checklist-editor">
 
        <Drawer
        class="item-drawer"
        :title="drawer.title"
        :visibility="drawer.open"
        @close-drawer="handleTableItem($event, 'cancel-create-edit-roster-checklist-item')"
        >
            <template v-slot:drawerContent>
                <div class="p-5">
                    <el-form
                    ref="drawerForm"
                    size="small"
                    :model="drawer.formFields"
                    :rules="drawer.formRules"
                    >
                        <el-form-item label="Type of Item" prop="typeId">
                            <template #label>
                                <span>Type of Item</span>
                                <el-tooltip 
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    content="This indicates what will need to be completed." placement="top">
                                    <i class="uil uil-question-circle absolute z-100"/>
                                </el-tooltip>
                            </template>
                            <ItemTypeComponent
                                prop="typeId"
                                :formFields="drawer.formFields"
                                :optionList="itemsByCompletionFrequencies"
                                :optionListFallback="rosterChecklistItemTypes"
                            />
                        </el-form-item>
                        <el-form-item label="Title/Question" prop="titleQuestion">
                            <template #label>
                                <span>Title/Question</span>
                                <el-tooltip 
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    content="This is the title or question that will be shown when looking at the Checklist." placement="top">
                                    <i class="uil uil-question-circle absolute z-100"/>
                                </el-tooltip>
                            </template>
                            <el-input v-model="drawer.formFields.titleQuestion" :rows="2" type="textarea"/>
                        </el-form-item>
                        <el-form-item label="Optional Description/Explanation" prop="descriptionExplanation">
                            <template #label>
                                <span>Description/Explanationn</span>
                                <el-tooltip 
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    content="This optional description or explanation will be shown alongside or as a popup next to the Item Title/Question when looking at the Checklist." placement="top">
                                    <i class="uil uil-question-circle absolute z-100"/>
                                </el-tooltip>
                            </template>
                            <el-input v-model="drawer.formFields.descriptionExplanation" :rows="2" type="textarea" />
                        </el-form-item>
                        <div v-if="isVehicleItem(itemById(rosterChecklistItemTypes, drawer.formFields.typeId, 'key'))">
                            
                            <el-switch v-model="drawer.formFields.requiredPhotoSpecifications"></el-switch>
                            <span class="text-sm text-gray-800 ml-2">Require Specific Photos</span>
                            
                            <template v-if="drawer.formFields.requiredPhotoSpecifications">
                                
                                <CustomListsOptionsSelect
                                class="mt-3"
                                v-model="drawer.photoSpecifications"
                                multiple
                                size="small"
                                listType="photo-log"
                                linkText="+ Add New Custom Photo Log Type"
                                placeholder="Select"
                                :customListEnabledArgs="[true, null, photoLogOptions]"
                                @change="(items) => updateOptionsSelect(items)"
                                />
                            </template>
                        </div>
                    </el-form>
                </div>
            </template>
            <template v-slot:drawerfooter>
                <div class="custom-drawer__footer p-4 flex justify-end">
                    <el-button @click="handleTableItem($event, 'cancel-create-edit-roster-checklist-item')">Cancel</el-button>
                    <el-button v-if="drawer.mode === 'start-create-roster-checklist-item'"
                        @click="handleTableItem($event, 'create-roster-checklist-item')"
                        type="primary">
                        Create
                    </el-button>
                    <el-button  v-else-if="drawer.mode === 'start-edit-roster-checklist-item'"
                        @click="handleTableItem($event, 'edit-roster-checklist-item')"
                        type="primary">
                        Update
                    </el-button>
                </div>
            </template>
        </Drawer>

        <div class="card col-span-8 xl:col-span-4 relative" v-loading="loading">
            <div class="clearfix">
                <div class="card-title">{{card.title}}</div>
                <div class="float-right">
                    <template v-if="card.editMode">
                        <template v-if="card.formData.id">
                            <template v-if="card.updateInProgress">
                                <el-button type="primary" :loading="true" size="mini" round>Save</el-button>
                                <el-button type="default" size="mini" round disabled>Cancel</el-button>
                            </template>
                            <template v-else>
                                <el-button type="primary" size="mini" @click="handleCard('save-roster-checklist')" round>Save</el-button>
                                <el-button type="primary" size="mini" @click="handleCard('cancel-save-roster-checklist')" round>Cancel</el-button>
                            </template>
                        </template>
                        <template v-else>
                            <el-button type="primary" size="mini" @click="handleCard('create-roster-checklist')" round>Create</el-button>
                            <el-button type="default" size="mini" @click="handleCard('cancel-create-roster-checklist')" round>Cancel</el-button>
                        </template>
                    </template>
                    <template v-else>
                        <el-button type="primary" size="mini" @click="handleCard('start-create-edit-roster-checklist')" round>Edit</el-button>
                    </template>
                </div>
                <el-form
                    ref="cardForm"
                    label-position="top"
                    size="medium"
                    :model="card.formDataEdit"
                    :rules="card.formRules"
                    :hide-required-asterisk="!card.editMode"
                    class="w-full card-form">
                    <div class="grid grid-cols-12 gap-x-4">
                        <el-form-item prop="name" class="col-span-12 md:col-span-6 non-edit-field">
                            <template #label>
                                <span>Roster Checklist Name</span>
                                <el-tooltip 
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    content="This name will be seen by users in Hera and by anyone completing or viewing the Roster Checklist." placement="top">
                                    <i class="uil uil-question-circle"/>
                                </el-tooltip>
                            </template>
                            <template v-if="card.editMode">
                                <el-input
                                    v-model="card.formDataEdit.name"
                                    class="mt-2"
                                    placeholder="Enter a Roster Checklist Name"/>
                            </template>
                            <template v-else-if="card.formData.name">
                                <CellToolTip :value.sync="card.formData.name" :maxLength="75">
                                    <div class="break-all" slot-scope="{textValue}">{{ textValue | text }}</div>
                                </CellToolTip>
                            </template>
                        </el-form-item>
                        <el-form-item prop="completionFrequencyId" class="col-span-12 md:col-span-6 non-edit-field">
                            <template #label>
                                <span>Completion Frequency</span>
                                <el-tooltip 
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    content="How often should this Roster Checklist be completed?" placement="top">
                                    <i class="uil uil-question-circle"/>
                                </el-tooltip>
                            </template>
                            <template v-if="card.editMode">
                                <el-select v-model="card.formDataEdit.completionFrequencyId" placeholder="Select a Completion Frequency" class="w-full mt-2" @change="changeCompletionFrequencyById">
                                    <el-option
                                    v-for="item in rosterChecklistCompletionFrequencies"
                                    :key="item.id"
                                    :label="item.option"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template v-else>
                                {{ itemById(rosterChecklistCompletionFrequencies, card.formData.completionFrequencyId, 'option') | text }}
                            </template>
                        </el-form-item>
                        <el-form-item prop="assignedToId" class="col-span-12 md:col-span-6 non-edit-field">
                            <template #label>
                                <span>Assigned To</span>
                                <el-tooltip
                                    v-show="tooltips.assignedTo.selectedMessage"
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    :content="tooltips.assignedTo.selectedMessage" placement="top">
                                    <i class="uil uil-question-circle"/>
                                </el-tooltip>
                            </template>
                            <template v-if="card.editMode">
                                <el-select :ref="card.assignedToRef" v-model="card.formDataEdit.assignedToId" placeholder="Select an option" class="w-full mt-2" @change="updateAssignedToId">
                                    <el-option
                                    v-for="item in optionsByCompletionFrequencies"
                                    :key="item.id"
                                    :label="item.option"
                                    :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </template>
                            <template v-else>
                                {{ itemById(rosterChecklistAssignedToOptions, card.formData.assignedToId, 'option') | text }}
                            </template>
                        </el-form-item>
                        
                        <el-form-item class="col-span-12 md:col-span-6 non-edit-field" v-if="card.editMode ? card.showUserMenuEditor : card.showUserMenu">
                            <template #label>
                                <span>Assigned Users</span>
                            </template>
                            <template v-if="card.editMode">
                                <el-select v-model="card.assignedUsers" placeholder="Select user" class="w-full mt-2" filterable multiple>
                                    <el-option
                                    v-for="item in usersWithLoginPermissions"
                                    :key="item.id"
                                    :label="item.firstName+' '+item.lastName"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template v-else>
                                <ol class="ml-5 list-disc list-outside" v-if="card.formData.assignedUsers && card.formData.assignedUsers.items && card.formData.assignedUsers.items.length">
                                    <li v-for="rclUsr in card.formData.assignedUsers.items" :key="rclUsr.userId">
                                        {{ itemById(userList, rclUsr.userId, (user) => user ? user.firstName + ' ' + user.lastName : null) | text }}
                                    </li>
                                </ol>
                                <template v-else>
                                    {{ 0 | text }}
                                </template>
                            </template>
                        </el-form-item>
                        <el-form-item class="col-span-12 md:col-span-6 non-edit-field" v-else>
                            <template #label>
                                <span>Vehicle Types</span>
                                <el-tooltip 
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    content="This Roster Checklist will only be required for Rostered Vehicles that match one of the Vehicle Types chosen here." placement="top">
                                    <i class="uil uil-question-circle"/>
                                </el-tooltip>
                            </template>
                            <template v-if="card.editMode">
                                <el-select v-model="card.vehicleTypes" placeholder="Select Vehicle Types" class="w-full mt-2" multiple>
                                    <el-option
                                    v-for="item in vehicleTypeList"
                                    :key="item.id"
                                    :label="item.option"
                                    :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </template>
                            <template v-else>
                                <ol class="ml-5 list-disc list-outside" v-if="card.formData.vehicleTypes && card.formData.vehicleTypes.items && card.formData.vehicleTypes.items.length">
                                    <li v-for="(vt, i) in card.formData.vehicleTypes.items" :key="vt.id + i">
                                        {{ itemById(vehicleTypeList, vt.vehicleTypeId, 'option') | text }}
                                    </li>
                                </ol>
                            </template>
                        </el-form-item>
                        <el-form-item class="tasks-form-item col-span-12 non-edit-flgld" :class="{'md:col-span-10  xs:col-span-12': specificSendTimeChecked}" v-if="card.editMode ? card.showUserMenuEditor : card.showUserMenu">
                            <template #label>
                                <span>Automatically create a task for the selected User(s) if the Roster Checklist is not yet completed...</span>
                            </template>
                            <template v-if="card.editMode">
                                <el-select v-model="card.checklistUserTasks" placeholder="Select tasks" class="w-full mt-2" popper-class="checkboxes-select" filterable multiple>
                                        <el-option
                                        v-for="item in rosterChecklistUserTasks"
                                        :key="item.id"
                                        :label="item.option"
                                        :value="item.id"
                                        >
                                            <span class="el-checkbox__input" :class="{'is-checked':card.checklistUserTasks && card.checklistUserTasks.includes(item.id)}">
                                                <span class="el-checkbox__inner"/>
                                            </span>
                                            <span class="el-checkbox__label">{{ item.option }}</span>
                                        </el-option>
                                </el-select>
                            </template>
                            <template v-else>
                                <ol class="ml-5 list-disc list-outside" v-if="card.formData.checklistTasks && card.formData.checklistTasks.items && card.formData.checklistTasks.items.length">
                                    <li v-for="rclChk in card.formData.checklistTasks.items.filter(t=>t.type === USER_TASK)" :key="rclChk.taskId">
                                        {{ itemById(rosterChecklistUserTasks, rclChk.taskId, 'option') | text }}
                                    </li>
                                </ol>
                                <template v-else>
                                    {{ null | text }}
                                </template>
                            </template>
                        </el-form-item>
                        <el-form-item class="tasks-form-item col-span-12 non-edit-field" :class="{'lg:col-span-10 xs:col-span-12': specificSendTimeChecked}" v-else>
                            <template #label>
                                <span>Automatically create a task for the selected Associate(s) if the Roster Checklist is not yet completed...</span>
                                <el-tooltip 
                                    class="item"
                                    popper-class="w-64"
                                    effect="dark"
                                    content="You will always be able to manually send Roster Checklist links from the Daily Roster, but these options allow you to send them automatically at set times." placement="top">
                                    <i class="uil uil-question-circle"/>
                                </el-tooltip>
                            </template>
                            <template v-if="card.editMode">
                                <el-select v-model="card.checklistAssociateTasks" placeholder="Select tasks" class="w-full mt-2" popper-class="checkboxes-select" filterable multiple>
                                        <el-option
                                        v-for="item in rosterChecklistAssociateTasks"
                                        :key="item.id"
                                        :label="item.option"
                                        :value="item.id"
                                        >
                                            <span class="el-checkbox__input" :class="{'is-checked':card.checklistAssociateTasks && card.checklistAssociateTasks.includes(item.id)}">
                                                <span class="el-checkbox__inner"/>
                                            </span>
                                            <span class="el-checkbox__label">{{ item.option }}</span>
                                        </el-option>
                                </el-select>
                            </template>
                            <template v-else>
                                <ol class="ml-5 list-disc list-outside" v-if="card.formData.checklistTasks && card.formData.checklistTasks.items && card.formData.checklistTasks.items.length">
                                    <li v-for="rclChk in card.formData.checklistTasks.items.filter(t=>t.type === ASSOCIATE_TASK)" :key="rclChk.taskId">
                                        {{ itemById(rosterChecklistAssociateTasks, rclChk.taskId, 'option') | text }}
                                    </li>
                                </ol>
                                <template v-else>
                                    {{ null | text }}
                                </template>
                            </template>
                        </el-form-item>
                        <el-form-item v-if="specificSendTimeChecked" prop="sendTime" class="send-time-form-item col-span-12 md:col-span-2 non-edit-field">
                            <template #label>
                                <span :class="$screen.breakpoint">Send Time</span>
                            </template>
                            <template v-if="card.editMode">
                                <el-time-picker
                                    class="sent-time-piker w-full mt-2"
                                    :class="$screen.breakpoint"
                                    prefix-icon="-"
                                    :editable="false"
                                    v-model="card.formDataEdit.sendTime"
                                    format="hh:mm a"
                                    value-format="HH:mm"
                                    X-default-value="timer(scope.row.time)"
                                    placeholder="Select time"/>
                            </template>
                            <template v-else>
                                {{ formatDate(card.formData.sendTime) | text }}
                            </template>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
        
        <TableCard tablecard-class="mt-4 rounded-lg" v-if="card.formData.id">
            <template v-slot:table-filters>
                <ContainerFilter inTable>
                <template v-slot:title>
                    <span class="uppercase">CHECKLIST ITEMS</span>
                </template>
                </ContainerFilter>
            </template>
            
            <!--Table-->
            <CustomTable
            class="items-table"
            :class="{'cursor-progress': rowTransition.updatingDynamo}"
            ref="items-table"
            inTable
            v-loading="loading || rowTransition.updatingDynamo"
            :columns="table.cols"
            :data="checklistItems"
            :totalRecords="totalRosterChecklistItems"
            emptyText="No Roster Checklist Items"
            footerTable="Total Roster Checklist Items"
            withTableMenu
            @click-menu="handleTableItem($event, 'start-create-roster-checklist-item', {})"
            :optionsHeader="table.optionsContextHeader"
            :optionsRows="[]"
            :draggable="isDesktop"
            @row-dragged="rowDragged"
            :paginationCurrentPage="1"
            :pagination="false"
            >
                <template #typeId="row">
                    <div class="inline-flex">
                        {{ itemById(rosterChecklistItemTypes, row.typeId, 'option') | text }}
                    </div>
                </template>
                <template #status="row">
                    <div class="inline-flex">
                        <span
                        class="font-bold rounded-full bg-opacity-70 px-3 text-xs max-w-full flex-initial"
                        :class="{'bg-red-500 text-red-900': row.status !== 'ENABLED', 'bg-green-500 text-green-900': row.status === 'ENABLED'}"
                        >{{row.status === 'ENABLED' ? 'Enabled' : 'Disabled (Not Shown)'}}</span>
                    </div>
                </template>
                <template #custom-dropdown="row">
                    <span class="context-menu mt-px mr-1" @click="popoverHandler($event, 'click', row)">
                        <i class="uil uil-ellipsis-h roster-checklist-items-popover-reference"></i>
                    </span>
                </template>
            </CustomTable>
        </TableCard>

        <el-popover
            v-for="count in 2" :key="count"
            ref="popoverElement"
            width="200"
            trigger="click"
            popper-class="checklist-items-popper"
            :open-delay="0"
            :close-delay="0"
            >
            <template v-if="popoverElement.item && !(rowTransition.updatingDynamo || rowTransition.updatingTableRow === rowTransition.TRANSITION_ROW_MOVING)">
                <div class="checklist-items-menu w-full" v-loading="rowTransition.updatingTableRow === rowTransition.TRANSITION_ROW_STATUS_CHANGING">
                    <div class="checklist-items-menu-item leading-8" @click="handleTableItem($event, 'start-edit-roster-checklist-item', popoverElement.item)">Edit Checklist Item</div>
                    <div class="el-dropdown-menu__item--divided"></div>
                    <div class="el-radio-group flex pt-2 pb-1">
                        <label class="el-button el-button--mini checklist-items-menu-item flex-1" :class="{'checklist-items-menu-item-enabled disable-cursor-pointer':popoverElement.item.status === 'ENABLED'}" @click="handleTableItem($event, 'enable-roster-checklist-item', popoverElement.item)">Enable</label>
                        <label class="el-button el-button--mini checklist-items-menu-item flex-1" :class="{'checklist-items-menu-item-enabled disable-cursor-pointer':popoverElement.item.status !== 'ENABLED'}" @click="handleTableItem($event, 'disable-roster-checklist-item', popoverElement.item)">Disable (Don't Show)</label>
                    </div>
                    <div class="el-radio-group flex pt-2 pb-2">
                        <label
                        class="el-button el-button--mini checklist-items-menu-item flex-1"
                        :class="{'is-disabled': popoverElement.disableUp}"
                        @click="!popoverElement.disableUp && handleTableItem($event, 'move-up-roster-checklist-item', popoverElement.item)"
                        >Move Up</label>
                        <label
                        class="el-button el-button--mini checklist-items-menu-item flex-1"
                        :class="{'is-disabled': popoverElement.disableDown}"
                        @click="!popoverElement.disableDown && handleTableItem($event, 'move-down-roster-checklist-item', popoverElement.item)"
                        >Move Down</label>
                    </div>
                    <div class="el-dropdown-menu__item--divided"></div>
                    <div class="checklist-items-menu-item leading-8" @click="handleTableItem($event, 'delete-roster-checklist-item', popoverElement.item)">Delete Checklist Item</div>
                </div>
            </template>
            <div v-else class="py-4" v-loading="true"/>
            

        </el-popover>
    </div>
</template>
<script>
import {API} from 'aws-amplify'
import {mapState, mapGetters} from "vuex"
import EventBus from '@/eventBus'
import TableCard from "@/components/TableCard/TableCard"
import ContainerFilter from "@/components/TableCard/ContainerFilter"
import SearchBox from "@/components/TableCard/SearchBox"
import CustomTable from "@/components/TableCard/CustomTable"
import Drawer from '@/components/shared/Drawer/Drawer'
import rosterChecklistMixin from '../rosterChecklist'
import { getRosterChecklist } from '../queries'
import CustomListsOptionsSelect from '@/components/CustomListsOptionsSelect';
import jsonOptions from './rosterChecklistOptions.json'
import ItemTypeComponent from './ItemTypeComponent'
import { nanoid } from 'nanoid'
import CellToolTip from '@/components/CellToolTip'
import { mapPhotoLogOptions } from  '@/utilities/optionCustomLists/helpers'

export default{
    mixins: [ rosterChecklistMixin ],
    props: {
        rosterChecklistId: String,
        parentProps: Object
    },
    components: {
        TableCard,
        CustomTable,
        SearchBox,
        ContainerFilter,
        Drawer,
        ItemTypeComponent,
        CellToolTip,
        CustomListsOptionsSelect
    },
    watch:{
        '$screen.breakpoint':(a,b)=>{
            console.log({a,b})
        }
    },
    data(){
        
        const TRANSITION_ROW_UPDATE_FINISH = 0
        const TRANSITION_ROW_MOVING = 1
        const TRANSITION_ROW_STATUS_CHANGING = 2

        return {
            loading: true,
            card: {
                editMode: false,
                title: null,
                formDataEdit: {},
                assignedUsers: [],
                checklistUserTasks: [],
                checklistAssociateTasks: [],
                vehicleTypes: [],
                formData: {},
                showUserMenu: false,
                showUserMenuEditor: false,
                duplicatedName: null,
                formRules: {
                    name: [
                        { required: true, trigger: 'change', validator: async (rule, value, callback)=>{

                            this.duplicatedName = null

                            if(!value){
                                callback(new Error('Name is required'))
                            }
                            if(value.length > 50){
                                callback(new Error('The name can be a maximum of 50 characters'))
                            }
                            let tableData = this.allRosterChecklists
                            const exists = tableData.find(rcl => rcl.name.toUpperCase() === value?.toUpperCase() && (this.rosterChecklistId ? this.rosterChecklistId !== rcl.id : true))
                            if(exists){

                                let lastCopies = tableData.filter(rcl => {
                                    return (this.rosterChecklistId ? this.rosterChecklistId !== rcl.id : true) &&
                                    rcl.name.toUpperCase().includes(value?.toUpperCase()) && /\(Copy(\s\d+)?\)$/g.exec(rcl.name)
                                })

                                if(lastCopies.length){

                                    const copyNumber = (value) => {
                                        const found = /\(Copy(\s(?<count>(\d+)))?\)$/g.exec(value)
                                        if(found){
                                            const { count } = found.groups || {}
                                            return parseInt(count) || 1
                                        }else{
                                            return 0
                                        }
                                    }

                                    const lastCopy = lastCopies.reduce((major, copy)=>{
                                        const count = copyNumber(copy.name)
                                        if(count > major)
                                            return count
                                        return major
                                    }, 0)

                                    this.duplicatedName = `${value} (Copy ${lastCopy+1})`
                                    callback(new Error(`"${value}" already exists in this Tenant, (Copy ${lastCopy+1}) will be added to avoid duplication.`))

                                }else{
                                    this.duplicatedName = `${value} (Copy)`
                                    callback(new Error(`"${value}" already exists in this Tenant, (Copy) will be added to avoid duplication.`))
                                }

                            }else{
                                callback()
                            }
                            
                        } },
                    ],
                    completionFrequencyId: [
                        { required: true, message: 'Completion Frequency is required', trigger: 'change' }
                    ],
                    userId: [
                        { required: true, message: 'User is required', trigger: 'change' }
                    ]
                },
                assignedToRef: 0,
                updateInProgress: false
            },
            drawer: {
                mode: null,
                title: null,
                open: false,
                formFields: {},
                photoSpecifications:{
                    items: []
                },
                formRules:{
                    typeId: [
                        { required: true, message: 'Type is required', trigger: 'change' },
                    ],
                    titleQuestion: [
                        { required: true, trigger: 'change', validator: (rule, value, callback)=>{
                            if(!value){
                                callback(new Error('Title/Question is required'))
                            }
                            else if(value.length > 200){
                                callback(new Error('The Title/Question can be a maximum of 200 characters'))
                            }
                            else if(
                                this.table.data.some(row=>row.titleQuestion === value && this.drawer.formFields.id !== row.id)
                            ){
                                callback(new Error('Title/Question is unique per Checklist'))
                            }else{
                                callback()
                            }
                        }}
                    ],
                    descriptionExplanation: [
                        { required: false, trigger: 'change', validator: (rule, value, callback)=>{
                            if(!!value && value.length > 1000){
                                callback(new Error('The Description/Explanation can be a maximum of 1000 characters'))
                            }else{
                                callback()
                            }
                        }}
                    ]
                },
                targetRow: null
            },
            table:{ 
                cols: [
                    { name: "Type of Item", col: "typeId", fixed: false, width: "210"},
                    { name: "Title/Question", col: "titleQuestion", fixed: false, width: "210"},
                    { name: "Description/Explanation", col: "descriptionExplanation", fixed: false, width: "260"},
                    { name: "Item Status", col: "status", fixed: false, width: "180"},
                ],
                data: [],
                optionsContextHeader: [
                    { label: "Add New Checklist Item", action: "add", divided:  false},
                ]
            },
            popoverElement: {
                show: null,
                reference: null,
                item: null,
                previousStyle: null,
                disableUp: true,
                disableDown: true,
                popoverIndex: 0
            },
            rowTransition: {
                TRANSITION_ROW_UPDATE_FINISH,
                TRANSITION_ROW_MOVING,
                TRANSITION_ROW_STATUS_CHANGING,
                animationSetTimeout: null,
                opposite: {
                    'row-moving-down' : 'row-moving-up',
                    'row-moving-up': 'row-moving-down'
                },
                updatingDynamo: false,
                updatingTableRow: TRANSITION_ROW_UPDATE_FINISH
            },
            tooltips: {
                assignedTo: {
                    selectedMessage: null
                }
            },
            options: {
                selectedCompletionFrequencyKey: null
            },
            allRosterChecklists: null,
            USER_TASK: "USER_TASK",
            ASSOCIATE_TASK: "ASSOCIATE_TASK" 
        }
    },
    computed:{
        ...mapState(['valuelists', 'userInfo']),
        ...mapGetters(['getCustomListLookupMap','vehicleTypeList']),
        ...mapState('subscriptionStore', [
            'userList',
        ]),

        usersWithLoginPermissions(){
            return this.userList.filter(u => u.permissionLogin)
        },

        rosterChecklistCompletionFrequencies(){
            return this.getCustomListLookupMap['roster-checklist-completion-frequency']?.options || []
        },
        rosterChecklistItemTypes(){
            return this.getCustomListLookupMap['roster-checklist-item-type']?.options || []
        },
        rosterChecklistAssignedToOptions(){
            return this.getCustomListLookupMap['roster-checklist-assigned-to-option']?.options || []
        },
        rosterChecklistUserTasks(){
            return this.getCustomListLookupMap['roster-checklist-user-task']?.options || []
        },
        rosterChecklistAssociateTasks(){
            return this.getCustomListLookupMap['roster-checklist-associate-task']?.options || []
        },
        photoLogOptions(){
            return this.getCustomListLookupMap['photo-log']?.options || []
        },
        isDesktop(){
            return !this.isAndroid() && !this.isIos()
        },
        checklistItems(){
            const types = this.rosterChecklistItemTypes
            return this.table.data?.filter(c => {
                if(this.options.selectedCompletionFrequencyKey === 'RCHKLST_CF_ONCE_PER_DAY'){
                    const itemType = types.find(i => i.id === c.typeId)
                    const isVehicleItem = this.isVehicleItem(itemType.key)
                    return !isVehicleItem
                }
                return true
            }) || []
        },
        totalRosterChecklistItems(){
            return this.checklistItems?.length
        },
        optionsByCompletionFrequencies(){
            return this.rosterChecklistAssignedToOptions.filter(item => jsonOptions.assignedTo[this.options.selectedCompletionFrequencyKey]?.options?.includes(item.key) )
        },
        itemsByCompletionFrequencies(){
            const assignedToKey = this.itemById(this.rosterChecklistAssignedToOptions, this.card.formDataEdit.assignedToId, 'key')
            return this.rosterChecklistItemTypes.filter(item=>{
                const typeOfItem = jsonOptions.assignedTo[this.options.selectedCompletionFrequencyKey]?.checklistItemsDrawer?.typeOfItem
                if(!typeOfItem?.default) return false
                let match = typeOfItem.default.includes(item.key)
                if(assignedToKey === 'RCHKLST_AS_SPECIFIC_HERA_USERS' && !match){
                    match = typeOfItem['RCHKLST_AS_SPECIFIC_HERA_USERS']?.includes(item.key)
                }
                return match
            })
        },
        specificSendTimeChecked(){
            if(this.card.editMode){
                const assignedToKey = this.itemById(this.rosterChecklistAssignedToOptions, this.card.formDataEdit.assignedToId, 'key')
                const op = {
                    user: {
                        vModel: this.card.checklistUserTasks,
                        optionsCustomList: this.rosterChecklistUserTasks,
                        key: "RCHKLST_UT_AT_SPECIFIC_TIME_OF_DAY"
                    },
                    associate: {
                        vModel: this.card.checklistAssociateTasks,
                        optionsCustomList: this.rosterChecklistAssociateTasks,
                        key: "RCHKLST_AT_AT_SPECIFIC_TIME_OF_DAY"
                    },
                }

                const selected = op[assignedToKey === 'RCHKLST_AS_SPECIFIC_HERA_USERS' ? 'user' : 'associate']
                return selected.vModel.some(taskId => this.itemById(selected.optionsCustomList, taskId, 'key') ===  selected.key)
            }else{
                const assignedToKey = this.itemById(this.rosterChecklistAssignedToOptions, this.card.formData.assignedToId, 'key')
                if(assignedToKey === 'RCHKLST_AS_SPECIFIC_HERA_USERS'){
                    const vModel = this.card.formData.checklistTasks?.items.filter(t=>t.type === this.USER_TASK)
                    return vModel?.some(rct => this.rosterChecklistUserTasks.some(ocl => rct.taskId === ocl.id && ocl.key ===  "RCHKLST_UT_AT_SPECIFIC_TIME_OF_DAY"))
                }else{
                    const vModel = this.card.formData.checklistTasks?.items.filter(t=>t.type === this.ASSOCIATE_TASK)
                    return vModel?.some(rct => this.rosterChecklistAssociateTasks.some(ocl => rct.taskId === ocl.id && ocl.key === "RCHKLST_AT_AT_SPECIFIC_TIME_OF_DAY"))
                }
            }

        }
    },
    methods:{

        formatDate(dateStr) {
            if(!dateStr) return 0
            const timeString = dateStr;

            // Split the string into hours and minutes
            const [hours, minutes] = timeString.split(':').map(Number);
            // Create a new Date object
            const date = new Date();

            // Set the hours and minutes
            date.setHours(hours);
            date.setMinutes(minutes);

            if (isNaN(date.getTime())) {
                return 0
            }

            // Get hours, minutes, and AM/PM indicator
            return date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
        },
        
        placeUserMenu(assignedToId, editMode){
            const assignedToKey = this.itemById(this.rosterChecklistAssignedToOptions, assignedToId, 'key')
            if(editMode){
                this.card.showUserMenuEditor = (assignedToKey === 'RCHKLST_AS_SPECIFIC_HERA_USERS')
            }else{
                this.card.showUserMenu = this.card.showUserMenuEditor = (assignedToKey === 'RCHKLST_AS_SPECIFIC_HERA_USERS')
            }
        },

        updateAssignedToId(assignedToId){
            this.card.assignedToRef++
            this.placeUserMenu(assignedToId, true)
        },

        changeCompletionFrequencyById(completionFrequencyId){
            const completionFrequency = this.itemById(this.rosterChecklistCompletionFrequencies, completionFrequencyId)
            const completionFrequencyKey = completionFrequency.key
            const activeAsignedTo = jsonOptions.assignedTo[completionFrequencyKey]
            this.tooltips.assignedTo.selectedMessage = activeAsignedTo.tooltip
            this.options.selectedCompletionFrequencyKey = completionFrequencyKey
            if(activeAsignedTo.default ){
                // if(!this.optionsByCompletionFrequencies.some(o => o.id === this.card.formDataEdit.assignedToId)){
                    const assignedToId = this.rosterChecklistAssignedToOptions.find(as => as.key === activeAsignedTo.default)?.id
                    this.card.formDataEdit.assignedToId = assignedToId
                    this.placeUserMenu(assignedToId, true)
                // }
            }
        },

        handleCard: async function(type){

            switch(type){

                case 'start-create-edit-roster-checklist':{
                    const {assignedUsers, checklistTasks, vehicleTypes, ...formData} = this.card.formData

                    this.placeUserMenu(formData.assignedToId, true)
                    this.card.formDataEdit = formData
                    this.card.assignedUsers = assignedUsers?.items.map(au => au.userId) || []
                    const checklistUserTasks = checklistTasks?.items.filter(t=>t.type===this.USER_TASK) || []
                    const checklistAssociateTasks = checklistTasks?.items.filter(t=>t.type===this.ASSOCIATE_TASK) || []
                    this.card.checklistUserTasks = checklistUserTasks.map(chkbx => chkbx.taskId)
                    this.card.checklistAssociateTasks = checklistAssociateTasks.map(chkbx => chkbx.taskId)
                    this.card.vehicleTypes = vehicleTypes?.items.map(vt => vt.vehicleTypeId) || []
                    this.card.editMode = true
                    break
                }

                case 'create-roster-checklist': {
                    
                    await this.$refs.cardForm.validate(this.checklistFormValidationCallBack(async ()=>{

                        try {

                            const rosterChecklistId = nanoid(9)
                        
                            const rosterChecklistVehicleTypeCreateInputs = this.card.vehicleTypes.map(vehicleTypeId => {
                                return {
                                    input:{
                                        vehicleTypeId,
                                        rosterChecklistId,
                                        group: this.userInfo.tenant.group
                                    }
                                }
                            })

                            const rosterChecklistUserCreateInputs = this.card.assignedUsers.map(userId => {
                                return {
                                    input:{
                                        userId,
                                        rosterChecklistId,
                                        group: this.userInfo.tenant.group
                                    }
                                }
                            })
                            
                            const rosterChecklistUserTaskCreateInputs = this.card.checklistUserTasks?.map(taskId => {
                                return {
                                    input:{
                                        taskId,
                                        type: this.USER_TASK,
                                        rosterChecklistId,
                                        group: this.userInfo.tenant.group
                                    }
                                }
                            }) || []

                            const rosterChecklistAssociateTaskCreateInputs = this.card.checklistAssociateTasks?.map(taskId => {
                                return {
                                    input:{
                                        taskId,
                                        type: this.ASSOCIATE_TASK,
                                        rosterChecklistId,
                                        group: this.userInfo.tenant.group
                                    }
                                }
                            }) || []

                            const multiQuery = {}

                            multiQuery.RosterChecklist = {
                                create: {
                                    input: {
                                        ...this.card.formDataEdit,
                                        id: rosterChecklistId,
                                        totalSimpleItems: 0,
                                        totalVehicleItems: 0,
                                        group: this.userInfo.tenant.group,
                                        tenantId: this.userInfo.tenant.id,
                                        status: 'ENABLED'
                                    }
                                },
                                fragmentName: "rosterChecklistFragment"
                            }

                            if(rosterChecklistVehicleTypeCreateInputs?.length){
                                multiQuery.RosterChecklistVehicleType = {
                                    create: rosterChecklistVehicleTypeCreateInputs,
                                    fragmentName: "rosterChecklistVehicleTypeFragment"
                                }
                            }

                            if(rosterChecklistUserCreateInputs?.length){
                                multiQuery.RosterChecklistUser = {
                                    create: rosterChecklistUserCreateInputs,
                                    fragmentName: "rosterChecklistUserFragment"
                                }
                            }
                            
                            if(rosterChecklistUserTaskCreateInputs.length || rosterChecklistAssociateTaskCreateInputs.length){
                                multiQuery.RosterChecklistTask = {
                                    create: [...rosterChecklistUserTaskCreateInputs, ...rosterChecklistAssociateTaskCreateInputs],
                                    fragmentName: "rosterChecklistTaskFragment"
                                }
                            }

                            let formData = {}

                            if(Object.keys(multiQuery).length){

                                let apiName = 'heraPublicApi';
                                let path = '/executeMutation';
                                let post = {
                                    body: {
                                        "type": 'rosterChecklist',
                                        "mutationName": 'createRosterChecklist',
                                        "multiQuery": multiQuery
                                    }
                                }

                                const responseData = await safeFunction(API.post)(apiName, path, post)
                                
                                formData = responseData.createRosterChecklist

                                if(formData){

                                    if(Array.isArray(formData) && formData.length){
                                        formData = formData[0]
                                    }

                                    formData.vehicleTypes = {
                                        items: responseData?.createRosterChecklistVehicleType || []
                                    }

                                    formData.assignedUsers = {
                                        items: responseData?.createRosterChecklistUser || []
                                    }

                                    formData.checklistTasks = {
                                        items: responseData?.createRosterChecklistTask || []
                                    }
                                }
                                
                            }

                            this.placeUserMenu(formData.assignedToId)
                            this.card.formData = formData
                            this.$router.replace({
                                name: 'SettingsRosterChecklistsEditor',
                                params: {
                                    rosterChecklistId: formData.id,
                                    parentProps: {
                                        formData: formData,
                                        tableData: this.allRosterChecklists
                                    }
                                }
                            });
                            this.card.editMode = false
                        } catch (error) {
                            this.displayUserError(error)
                        }
                        this.$refs.cardForm.resetFields()

                    }))
                    break
                }

                case 'save-roster-checklist':{
                    await this.$refs.cardForm.validate(this.validationCallBack(async()=>{
                        this.card.updateInProgress = true
                        const rclInput = {
                            ...this.card.formDataEdit,
                            group: this.userInfo.tenant.group
                        }
                        
                        delete rclInput.createdAt
                        delete rclInput.updatedAt
                        delete rclInput.completionFrequency
                        delete rclInput.rosterChecklistItems

                        let vehicleTypes = this.card.formData.vehicleTypes.items

                        const vehicleTypesToAdd = this.card.vehicleTypes.filter(vtId => {
                            return !vehicleTypes.some(rclVt => rclVt.vehicleTypeId === vtId)
                        }).map(vehicleTypeId =>{
                            return {
                                input: {
                                    vehicleTypeId,
                                    rosterChecklistId: rclInput.id,
                                    group: this.userInfo.tenant.group
                                }
                            }
                        })

                        const vehicleTypesToDelete = vehicleTypes.filter(rclVt => {
                            return !this.card.vehicleTypes.some(vtId => rclVt.vehicleTypeId === vtId)
                        }).map(rclVt => {
                            return {
                                input: {
                                    id: rclVt.id
                                }
                            }
                        })

                        let assignedUsers = this.card.formData.assignedUsers.items

                        const assignedUsersToAdd = this.card.assignedUsers.filter(rclUsrId => {
                            return !assignedUsers.some(rclUsr => rclUsr.userId === rclUsrId)
                        }).map(userId =>{
                            return {
                                input: {
                                    userId,
                                    rosterChecklistId: rclInput.id,
                                    group: this.userInfo.tenant.group
                                }
                            }
                        })

                        const assignedUsersToDelete = assignedUsers.filter(rclUsr => {
                            return !this.card.assignedUsers.some(rclUsrId => rclUsr.userId === rclUsrId)
                        }).map(rclUsr => {
                            return {
                                input: {
                                    id: rclUsr.id
                                }
                            }
                        })

                    
                        let checklistUserTasks = this.card.formData.checklistTasks.items.filter(t=>t.type===this.USER_TASK)

                        const userTasksToAdd = this.card.checklistUserTasks.filter(rclChkId => {
                            return !checklistUserTasks.some(rclChk => rclChk.taskId === rclChkId)
                        }).map(taskId =>{
                            return {
                                input: {
                                    taskId,
                                    type: this.USER_TASK,
                                    rosterChecklistId: rclInput.id,
                                    group: this.userInfo.tenant.group
                                }
                            }
                        })

                        const userTasksToDelete = checklistUserTasks.filter(rclChk => {
                            return !this.card.checklistUserTasks.some(rclChkId => rclChk.taskId === rclChkId)
                        }).map(rclChk => {
                            return {
                                input: {
                                    id: rclChk.id
                                }
                            }
                        })

                        let checklistAssociateTasks = this.card.formData.checklistTasks.items.filter(t=>t.type===this.ASSOCIATE_TASK)

                        const associateTasksToAdd = this.card.checklistAssociateTasks.filter(rclChkId => {
                            return !checklistAssociateTasks.some(rclChk => rclChk.taskId === rclChkId)
                        }).map(taskId =>{
                            return {
                                input: {
                                    taskId,
                                    type: this.ASSOCIATE_TASK,
                                    rosterChecklistId: rclInput.id,
                                    group: this.userInfo.tenant.group
                                }
                            }
                        })

                        const associateTasksToDelete = checklistAssociateTasks.filter(rclChk => {
                            return !this.card.checklistAssociateTasks.some(rclChkId => rclChk.taskId === rclChkId)
                        }).map(rclChk => {
                            return {
                                input: {
                                    id: rclChk.id
                                }
                            }
                        })

                        const multiQuery = {
                            RosterChecklist:{
                                update: {
                                    input: rclInput
                                },
                                fragmentName: "rosterChecklistFragment"
                            },
                            RosterChecklistVehicleType:{
                                create: vehicleTypesToAdd,
                                delete: vehicleTypesToDelete,
                                fragmentName: "rosterChecklistVehicleTypeFragment"
                            },
                            RosterChecklistUser:{
                                create: assignedUsersToAdd,
                                delete: assignedUsersToDelete,
                                fragmentName: "rosterChecklistUserFragment"
                            },
                            RosterChecklistTask:{
                                create: [...userTasksToAdd, ...associateTasksToAdd],
                                delete: [...userTasksToDelete, ...associateTasksToDelete],
                                fragmentName: "rosterChecklistTaskFragment"
                            }
                        }

                        try {

                            let apiName = 'heraPublicApi';
                            let path = '/executeMutation';
                            let post = {
                                body: {
                                    "type": 'rosterChecklist',
                                    "mutationName": 'updateRosterChecklist',
                                    "multiQuery": multiQuery
                                }
                            }

                            const responseData = await safeFunction(API.post)(apiName, path, post)

                            const {
                                updateRosterChecklist,
                                deleteRosterChecklistVehicleType,
                                createRosterChecklistVehicleType,
                                createRosterChecklistUser,
                                deleteRosterChecklistUser,
                                createRosterChecklistTask,
                                deleteRosterChecklistTask
                            } = responseData || {}

                            let formData = {...this.card.formData}
                            if(updateRosterChecklist?.length){
                                formData = {...formData, ...updateRosterChecklist[0]}
                            }

                            if(deleteRosterChecklistVehicleType?.length){
                                formData.vehicleTypes.items = vehicleTypes.filter(au => !deleteRosterChecklistVehicleType.some(dUsr => dUsr.userId === au.userId))
                            }
                            if(createRosterChecklistVehicleType?.length){
                                formData.vehicleTypes.items.push(...createRosterChecklistVehicleType)
                            }

                            if(deleteRosterChecklistUser?.length){
                                formData.assignedUsers.items = assignedUsers.filter(au => !deleteRosterChecklistUser.some(dUsr => dUsr.userId === au.userId))
                            }
                            if(createRosterChecklistUser?.length){
                                formData.assignedUsers.items.push(...createRosterChecklistUser)
                            }

                            if(deleteRosterChecklistTask?.length){
                                formData.checklistTasks.items = this.card.formData.checklistTasks.items.filter(chk => !deleteRosterChecklistTask.some(dChk => dChk.taskId === chk.taskId))
                            }
                            if(createRosterChecklistTask?.length){
                                formData.checklistTasks.items.push(...createRosterChecklistTask)
                            }

                            this.placeUserMenu(formData.assignedToId)
                            this.card.formData = formData
                        } catch (error) {
                            this.displayUserError(error)
                        }
                        
                        EventBus.$emit('change-roster-checklist-breadcrumb-child', this.card.formData.name)
                        
                        this.card.updateInProgress = false

                        this.card.editMode = false
                        
                        this.$refs.cardForm.resetFields()
                    }))
                    break
                }

                case 'cancel-create-roster-checklist':{
                    this.$router.go(-1)
                    break
                }

                case 'cancel-save-roster-checklist':{
                    this.card.editMode = false
                    break
                }
            }
        },

        
        updateOptionsSelect(newOptionsSelected) {
            let selectedItems = newOptionsSelected.items ? newOptionsSelected.items : newOptionsSelected;
            selectedItems = mapPhotoLogOptions(selectedItems)
            this.drawer.formFields.photoSpecifications = JSON.stringify(selectedItems)
            this.drawer.photoSpecifications.items = selectedItems
        },

        isVehicleItem(key){
            return key?.includes('PHOTO') || key?.includes('ODOMETER')
        },

        countItems(){
            const items = this.checklistItems
            const types = this.rosterChecklistItemTypes
            let { vehicleItems, simpleItems } = types.reduce((map, curr)=>{

                const filteredItems = items.filter(i => i.typeId === curr.id && i.status === 'ENABLED')
                if(this.isVehicleItem(curr.key)){
                    map.vehicleItems += filteredItems.length
                }else{
                    map.simpleItems += filteredItems.length
                }
                return map
            }, {vehicleItems: 0, simpleItems: 0})
            return { vehicleItems, simpleItems}
        },

        async handleTableItem(event, command, row){

            switch(command){
                
                case 'start-create-roster-checklist-item':{
                    this.drawer.open = true
                    this.drawer.title = 'Add New Checklist Item'
                    this.drawer.mode = command
                    this.drawer.formFields = {}
                    this.drawer.photoSpecifications.items = []
                    this.targetRow = null
                    break
                }

                case 'start-edit-roster-checklist-item':{
                    this.drawer.open = true
                    this.drawer.title = 'Edit Checklist Item'
                    this.drawer.mode = command
                    this.drawer.formFields = {...row}
                    let photoSpecifications
                    try {
                        photoSpecifications = JSON.parse(row.photoSpecifications)
                    } catch (error) {
                        photoSpecifications = []
                    }
                    this.drawer.photoSpecifications.items = photoSpecifications
                    this.drawer.targetRow = this.table.data.find(i=>i.id === row.id)
                    break
                }

                case 'create-roster-checklist-item':{
                    let lastIndex
                    if(this.table.data?.length){
                        lastIndex = this.table.data.length - 1
                        if(this.table.data[lastIndex].order){
                            lastIndex = this.table.data[lastIndex].order
                        }
                    }else{
                        lastIndex = -1
                    }
                    await this.$refs.drawerForm.validate(this.validationCallBack(async()=>{
                        
                        const rclItemInput = {
                            ...this.drawer.formFields,
                            rosterChecklistId: this.card.formData.id,
                            status: 'ENABLED',
                            group: this.userInfo.tenant.group,
                            order: lastIndex + 1
                        }

                        const itemCount = this.countItems()
                            
                        const itemType = this.rosterChecklistItemTypes.find(i => i.id === rclItemInput.typeId)

                        const isVehicleItem = this.isVehicleItem(itemType.key)

                        if(isVehicleItem){
                            itemCount.vehicleItems += 1
                        }else{
                            itemCount.simpleItems += 1
                        }

                        const rclInput = {
                            id: this.card.formData.id,
                            totalSimpleItems: itemCount.simpleItems,
                            totalVehicleItems: itemCount.vehicleItems
                        }

                        const multiQuery = {
                            RosterChecklistItem: {
                                create: {
                                    input: rclItemInput
                                },
                                fragmentName: 'rosterChecklistItemFragment'
                            },
                            RosterChecklist: {
                                update: {
                                    input: rclInput
                                },
                                fragmentName: 'rosterChecklistFragment'
                            }
                        }

                        let apiName = 'heraPublicApi';
                        let path = '/executeMutation';
                        let post = {
                            body: {
                                "type": 'rosterChecklist',
                                "mutationName": 'createRosterChecklistItem',
                                "multiQuery": multiQuery
                            }
                        }

                        const resCreate = await safeFunction(API.post)(apiName, path, post);

                        const newItem = resCreate.createRosterChecklistItem?.[0]

                        this.table.data.push(newItem)
                        this.drawer.open = false
                        this.$refs.drawerForm.resetFields()
                    }))
                    break
                }

                case 'enable-roster-checklist-item': case 'disable-roster-checklist-item': case 'edit-roster-checklist-item': {
                    const _this = this
                    const performUpdate = async function(rclInput, rclItemInput, callback){

                        const multiQuery = {
                            RosterChecklistItem: {
                                update: {
                                    input: rclItemInput
                                },
                                fragmentName: "rosterChecklistItemFragment"
                            }
                        }

                        if(rclInput){
                            multiQuery.RosterChecklist = {
                                update: {
                                    input: rclInput
                                },
                                fragmentName: "rosterChecklistFragment"
                            }
                        }

                        let apiName = 'heraPublicApi';
                        let path = '/executeMutation';
                        let post = {
                            body: {
                                "type": 'rosterChecklist',
                                "mutationName": 'updateRosterChecklistItem',
                                "multiQuery": multiQuery
                            }
                        }

                        const resUpdate = await safeFunction(API.post)(apiName, path, post)
                        const updatedItems = resUpdate?.updateRosterChecklistItem
                        
                        const rosterChecklistItem = updatedItems?.[0]
                        
                        callback(rosterChecklistItem)
                    }

                    if(command === 'edit-roster-checklist-item'){
                        await this.$refs.drawerForm.validate(this.validationCallBack(async()=>{
                            const rclItemInput = {
                                ...this.drawer.formFields,
                                group: this.userInfo.tenant.group
                            }
                            delete rclItemInput.createdAt
                            delete rclItemInput.updatedAt
                            await performUpdate(null, rclItemInput, rosterChecklistItem => {
                                const keys = Object.keys(rosterChecklistItem)
                                keys.forEach(k => (this.drawer.targetRow[k] = rosterChecklistItem[k]) )
                                this.drawer.open = false
                            })
                            this.$refs.drawerForm.resetFields()
                        }))
                    }
                    else{
                        
                        const itemCount = this.countItems()
                        
                        const itemType = this.rosterChecklistItemTypes.find(i => i.id === row.typeId)
                        
                        const isVehicleItem = this.isVehicleItem(itemType.key)
                        
                        const statusChanged = ['enable-roster-checklist-item', 'disable-roster-checklist-item'].find(c => c === command)
                        
                        let valueToAdd = 0
                        if(row.status !== 'ENABLED' && statusChanged === 'enable-roster-checklist-item'){
                            valueToAdd = 1
                        }
                        if(row.status === 'ENABLED' && statusChanged === 'disable-roster-checklist-item'){
                            valueToAdd = -1
                        }

                        if(statusChanged && valueToAdd == 0){
                            return
                        }

                        this.rowTransition.updatingTableRow = this.rowTransition.TRANSITION_ROW_STATUS_CHANGING
                        
                        if(isVehicleItem){
                            itemCount.vehicleItems += valueToAdd
                        }else{
                            itemCount.simpleItems += valueToAdd
                        }

                        const willBeEnabled = statusChanged ? (command === 'enable-roster-checklist-item') : (row.status === 'ENABLED')

                        const rclItemInput = {
                            id: row.id,
                            status: willBeEnabled ? 'ENABLED' : 'DISABLED'
                        }

                        const rclInput = {
                            id: this.card.formData.id,
                            totalSimpleItems: itemCount.simpleItems,
                            totalVehicleItems: itemCount.vehicleItems
                        }

                        await performUpdate(rclInput, rclItemInput, rosterChecklistItem => {
                            const indexOfThisRow = _this.table.data.findIndex(r => r.id === row.id)
                            const thisRow = _this.table.data[indexOfThisRow]
                            thisRow.status = row.status = rosterChecklistItem.status
                        })
                        this.rowTransition.updatingTableRow = this.rowTransition.TRANSITION_ROW_UPDATE_FINISH
                    }
                    break
                }

                case 'delete-roster-checklist-item':{

                    const performDelete = await this.$confirm('This will permanently delete this Roster Checklist Item. Continue?', 'Warning', {
                        type: 'warning',
                        confirmButtonText: 'Delete',
                        cancelButtonText: 'Cancel',
                    }).catch(e => e )

                    if(performDelete === 'confirm'){
                        const rowIndex = this.table.data.findIndex(i=>i.id === row.id)
                        if(rowIndex > -1){
                            this.rowTransition.updatingDynamo = true

                            let rclInput

                            if(row.status === 'ENABLED'){
                                const itemCount = this.countItems()
                                
                                const itemType = this.rosterChecklistItemTypes.find(i => i.id === row.typeId)

                                const isVehicleItem = this.isVehicleItem(itemType.key)

                                if(isVehicleItem){
                                    itemCount.vehicleItems -= 1
                                }else{
                                    itemCount.simpleItems -= 1
                                }

                                rclInput = {
                                    id: this.card.formData.id,
                                    totalSimpleItems: itemCount.simpleItems,
                                    totalVehicleItems: itemCount.vehicleItems
                                }

                            }
                            
                            const tableData = [...this.table.data]
                            tableData.splice(rowIndex, 1)
                            const itemsInput = this.getItemRange(rowIndex, tableData.length - 1, order => {
                                const { id } = tableData[order]
                                return {
                                    input: {
                                        id: id,
                                        order
                                    }
                                }
                            })
                            
                            const multiQuery = {
                                RosterChecklistItem: {
                                    delete:{
                                        input: {
                                            id: row.id
                                        }
                                    },
                                    update: itemsInput,
                                    fragmentName: 'rosterChecklistItemFragment'
                                }
                            }

                            if(rclInput){
                                multiQuery.RosterChecklist = {
                                    update: {
                                        input: rclInput
                                    },
                                    fragmentName: 'rosterChecklistFragment'
                                }
                            }

                            let apiName = 'heraPublicApi';
                            let path = '/executeMutation';
                            let post = {
                                body: {
                                    "type": 'rosterChecklist',
                                    "mutationName": 'deleteRosterChecklistItem',
                                    "multiQuery": multiQuery
                                }
                            }

                            const resDelete = await safeFunction(API.post)(apiName, path, post)
                            
                            const updatedItems = resDelete?.updateRosterChecklistItem
                            if(updatedItems?.length){
                                updatedItems.forEach(row=>{
                                    tableData[row.order] = row
                                })
                            }
                            const deletedItem = resDelete?.deleteRosterChecklistItem?.[0]
                            if(deletedItem){
                                this.table.data = tableData
                            }
                            
                            this.rowTransition.updatingDynamo = false
                        }
                    }
                    
                    break
                }
                
                case 'cancel-create-edit-roster-checklist-item':{
                    this.$refs.drawerForm.resetFields()
                    this.drawer.open = false
                    break
                }

                case 'move-up-roster-checklist-item':{
                    this.moveRow('row-moving-up', row)
                    break
                }

                case 'move-down-roster-checklist-item':{
                    this.moveRow('row-moving-down', row)
                    break
                }

            }

            if(command === 'start-edit-roster-checklist-item' || command === 'delete-roster-checklist-item'){
                this.popoverHandler(event, false, row)
            }
        },

        moveRow(animationType, row){

            if(this.rowTransition.animationSetTimeout || this.rowTransition.updatingDynamo) return
            
            const itemsTable = this.$refs['items-table']

            const indexOfThisRow = this.table.data.findIndex(r => r.id === row.id)
            let steps

            switch(animationType){
                case 'row-moving-up':
                    if(indexOfThisRow > 0 ){
                        steps = -1
                    }else{
                        return
                    }
                    break
                case 'row-moving-down':
                    if(indexOfThisRow < this.table.data.length - 1){
                        steps = 1
                    }else{
                        return
                    }
                    break
            }
            this.rowTransition.updatingTableRow = this.rowTransition.TRANSITION_ROW_MOVING
            const domElements = document.querySelectorAll('.el-table__body-wrapper tr')
            const thisRowElement = domElements[indexOfThisRow]
            const nextIndex = indexOfThisRow + steps
            const targetRowElement = domElements[nextIndex]

            thisRowElement.classList.add(animationType)
            targetRowElement.classList.add(this.rowTransition.opposite[animationType])

            const domFixedElements = document.querySelectorAll('.el-table__fixed-body-wrapper tr .roster-checklist-items-popover-reference')
            const domFixedTr = domFixedElements[nextIndex]
            
            // A time span for the effect of moving the position of the rows
            this.rowTransition.animationSetTimeout = setTimeout(async _this=>{

                clearTimeout(_this.rowTransition.animationSetTimeout)
                _this.rowTransition.animationSetTimeout = null

                const [ popElement ] = _this.table.data.splice(indexOfThisRow, 1)
                _this.table.data.splice(nextIndex, 0, popElement)
                
                thisRowElement.classList.remove(animationType)
                targetRowElement.classList.remove(this.rowTransition.opposite[animationType])
                await this.rowDragged({ oldIndex: indexOfThisRow, newIndex: nextIndex}, this.rowTransition.TRANSITION_ROW_MOVING)
                this.rowTransition.updatingTableRow = this.rowTransition.TRANSITION_ROW_UPDATE_FINISH
            }, 200, this)
            this.popoverHandler({target: domFixedTr}, true, this.popoverElement.item)
        },

        popoverHandler(event, show, row){
            this.popoverElement.item = row
            const popoverEls = this.$refs['popoverElement']
            const oldPopper = popoverEls[this.popoverElement.popoverIndex]

            oldPopper?.doClose()
            oldPopper && (oldPopper.referenceElm = undefined)
            oldPopper?.updatePopper()

            this.popoverElement.popoverIndex = [1, 0][this.popoverElement.popoverIndex]

            if(show){
                const trEl = event.target.closest('tr')
                this.popoverElement.disableUp = !trEl.previousSibling || trEl.previousSibling.nodeName !== 'TR'
                this.popoverElement.disableDown = !trEl.nextSibling || trEl.nextSibling.nodeName !== 'TR'
                const newPopper = popoverEls[this.popoverElement.popoverIndex]
                const style = newPopper.$refs.popper.getAttribute('style')
                const displayNone = /display\s*:\s*none/gi.test(style)

                if(displayNone){
                    newPopper.referenceElm = event.target
                    newPopper.updatePopper()
                    newPopper.doShow()
                }
            }else{
                this.popoverElement.item = null
            }

        },

        loadData: async function(){

            let breadcrumbChild
            
            if(this.parentProps?.formData?.id){ // edit

                breadcrumbChild = this.parentProps.formData.name
                const {rosterChecklistItems, ...formData} = this.parentProps.formData
                this.changeCompletionFrequencyById(formData.completionFrequencyId)
                this.placeUserMenu(formData.assignedToId)
                this.card.formData = formData
                this.table.data = rosterChecklistItems?.items || []
                this.card.title = `Roster Checklist Details`

            }else if(this.rosterChecklistId && this.rosterChecklistId !== 'create-new'){ // edit from url

                const rosterChecklist = await this.api(getRosterChecklist, { id: this.rosterChecklistId })
                const {rosterChecklistItems, ...formData} = rosterChecklist.data.getRosterChecklist
                this.changeCompletionFrequencyById(formData.completionFrequencyId)
                this.placeUserMenu(formData.assignedToId)
                this.card.formData = formData
                this.table.data = rosterChecklistItems?.items.sort((a, b)=> a.order - b.order) || []
                breadcrumbChild = this.card.formData.name
                this.card.title = `Roster Checklist Details`

            }else{ // create

                breadcrumbChild = `Create a New Roster Checklist`
                this.card.title = breadcrumbChild
                this.card.editMode = true

            }

            if(this.parentProps?.tableData){
                this.allRosterChecklists = this.parentProps.tableData
            }else{
                this.allRosterChecklists = await this.getRosterChecklists()
            }

            EventBus.$emit('change-roster-checklist-breadcrumb-child', breadcrumbChild)
            this.loading = false
        },
        bodyClickHandler(event){
            event.preventDefault()
            if(!event.target.classList.contains('roster-checklist-items-popover-reference')){
                this.popoverHandler(event, false, this.popoverElement.item)
            }
        },

        checklistFormValidationCallBack(callback){
            if(this.duplicatedName){
                this.card.formDataEdit.name = this.duplicatedName
                this.duplicatedName = null
            }
            return this.validationCallBack(callback)
        },

        validationCallBack: (callback) => {
            return async (valid) => {
                if(valid){
                    await callback(valid)
                }else if (!valid) {
                    throw{
                        errors: [
                            { message: "Please fill out all required fields."}
                        ]
                    }
                }
            }
        },
        getItemRange(start, end, callback){
            const length = (end - start) + 1
            return Array.from({ length }, (_, i) => {
                const order = start + i
                return callback ? callback(order) : order
            })
        },
        async rowDragged({oldIndex, newIndex}, transition){
            if(this.rowTransition.updatingDynamo){
                setTimeout(this.rowDragged, 125, {oldIndex, newIndex})
                return
            }
            if(transition !== this.rowTransition.TRANSITION_ROW_MOVING && this.popoverElement.item){
                this.popoverHandler(event, false, this.popoverElement.item)
            }
            this.rowTransition.updatingDynamo = true
            let start, end
            if(oldIndex < newIndex){
                start = oldIndex
                end = newIndex
            }else{
                start = newIndex
                end = oldIndex
            }
            const itemsInput = this.getItemRange(start, end, order => {
                const row = this.table.data[order]
                return {
                    input: {
                        id: row.id,
                        order
                    }
                }
            })
            
            const multiQuery = {
                RosterChecklistItem: {
                    update: itemsInput,
                    fragmentName: 'rosterChecklistItemFragment'
                }
            }

            let apiName = 'heraPublicApi';
            let path = '/executeMutation';
            let post = {
                body: {
                    "type": 'rosterChecklist',
                    "mutationName": 'updateRosterChecklistItem',
                    "multiQuery": multiQuery
                }
            }

            const resUpdate = await safeFunction(API.post)(apiName, path, post)

            const updatedItems = resUpdate?.updateRosterChecklistItem
            if(updatedItems?.length){
                const tableData = [...this.table.data]
                updatedItems.forEach(row=>{
                    tableData[row.order] = row
                })
                this.table.data = tableData
            }
            this.rowTransition.updatingDynamo = false
        }
    },
    mounted(){
        this.$nextTick(async ()=>{
            await this.loadData()
            document.body.addEventListener('click', this.bodyClickHandler)
        })
    },
    beforeDestroy(){
        document.body.removeEventListener('click', this.bodyClickHandler)
    }
}
</script>
<style>
.checklist-items-popper{
    @apply px-0 py-2 w-64 !important;
}
.checklist-items-menu .checklist-items-menu-item{
    @apply text-gray-700 hover:text-blue-600 hover:bg-gray-200 cursor-pointer;
}
.checklist-items-menu .checklist-items-menu-item.el-button{
    @apply h-8 leading-4;
}
.checklist-items-menu .el-dropdown-menu__item--divided::before{
    @apply bg-transparent !important;
}
.checklist-items-menu > div{
    @apply px-4 !important;
}
.checklist-items-menu .el-radio-group{
    @apply w-full inline-flex !important;
}
.checklist-items-menu .el-radio-group .el-radio-button, .checklist-items-menu .el-radio-group .el-radio-button .el-radio-button__inner{
    @apply w-full !important;
}
.checklist-items-menu .el-radio-group .el-button:nth-child(1){
    border-radius: 5px 0px 0px 5px;
    border-right-color: theme('colors.gray.300');
}
.checklist-items-menu .el-radio-group .el-button:nth-child(1):not(.is-disabled):hover{
    border-right-color: theme('colors.blue.300');
}
.checklist-items-menu .el-radio-group .el-button:nth-child(1):active{
    border-right-color: theme('colors.blue.700');
}
.checklist-items-menu .el-radio-group .el-button:nth-child(2){
    @apply ml-0 !important;
    border-radius: 0px 5px 5px 0px;
    border-left-color: theme('colors.gray.300');
}
.checklist-items-menu .el-radio-group .el-button:nth-child(2):not(.is-disabled):hover{
    border-left-color: theme('colors.blue.300');
}
.checklist-items-menu .el-radio-group .el-button:nth-child(2):active{
    border-left-color: theme('colors.blue.700');
}
.checklist-items-menu .el-radio-group .el-button:active, .checklist-items-menu-item-enabled{
    @apply bg-blue-700 border-blue-700 text-white !important;
}
.checklist-items-menu-item-enabled.disable-cursor-pointer{
    @apply cursor-default !important;
}
.checkboxes-select li.selected::after{
    display: none;
}
:where(.row-moving-up, .row-moving-down){
    animation-direction: normal;
}
.row-moving-up{
    animation: moveUp .21s normal;
}
.row-moving-down{
    animation: moveDown .21s normal;
}
@keyframes moveUp {
    0% {
        transform: translate(0, -10px);    
    }
    100% {
        transform: translate(0px, -50px);
    }
}
@keyframes moveDown {
    0% {
        transform: translate(0, 10px);    
    }
    100% {
        transform: translate(0px, 50px);
    }
}

:where(.tasks-form-item, .send-time-form-item) .el-form-item__label{
    line-height: 1.2rem !important;
    margin-bottom: 0.7rem !important;
}
.sent-time-piker{
    width: auto !important;
    position: absolute;
}
.sent-time-piker.md input{
    max-width: 150px;
}
.sent-time-piker.lg input{
    max-width: 160px;
}
.sent-time-piker.xl input{
    max-width: 200px;
}
.sent-time-piker.\\u0031 xl input{
    max-width: 250px;
    background: red;
}
body:has(.roster-checklist-editor) .el-loading-mask{
    @apply bg-gray-100 bg-opacity-60 !important;
}
body:has(.roster-checklist-editor) .checklist-items-popper:has(.updating-dynamo){
    @apply opacity-50 pointer-events-none !important;
}
.items-table tbody .cell{
    @apply py-2;
}
.items-table tbody .cell:has( .typeId-class, .titleQuestion-class, .descriptionExplanation-class) span{
    @apply leading-5 !important;
}
.item-drawer .el-form-item__content{
    z-index: 1 !important;
}
.item-drawer .el-form-item__label{
    z-index: 3 !important;
    position: relative !important;
}
</style>