<template>
    <div>
        <div 
            @click="$emit('triggerMobileMenu')" 
            id="mobile-menu-wrapper" 
            v-show="hamburgerActive" 
            class="fixed top-0 left-0 w-full h-full -ml-56 z-50 overflow-hidden bg-black" 
            :class="{'bg-opacity-50': hamburgerActive, 'bg-opacity-0': !hamburgerActive}">
        </div>
        <el-menu
            v-show="hamburgerActive"
            class="FixedImp right-0 mobile-menu w-56 z-50 pt-6"
            text-color="#606266">
            <span class="absolute right-1 top-1 z-50" @click="$emit('click-mobile-nav')">
                <i class="icon-button uil uil-multiply text-2xl cursor-pointer hover:text-brand"></i>
            </span>
            <div class="scroll-container">
                <div class="user-title font-bold">{{ userInfo.tenant.companyName }}</div>
                <div class="user-title font-light text-xs">{{userInfo.firstName}} {{userInfo.lastName}}</div>
                <div class="user-title font-light text-xs">{{userInfo.email}}</div>
                <div class="user-title font-light text-xs pb-4"><company-time></company-time></div>
                <div class="mobile-modules border-t border-gray-600 border-b pt-1 mb-4">
                    <el-menu-item>
                        <NavButton title="Notifications" icon="uil uil-bell" :to="{name: 'NotificationIndex', params:{optionID:'general'}}">
                            <template #default>
                                <Notifications class="h-16 ml-2 leading-10"/>
                            </template>    
                        </NavButton>
                    </el-menu-item>
                </div>
                <el-menu-item-group class="mobile-modules">
                    <el-menu-item>
                        <NavButton title="Performance & Coaching" icon="uil-chart-line" :to="{name: 'Dashboard'}"/>
                    </el-menu-item>
                    <el-menu-item>
                        <NavButton title="Rostering & Checklists" icon="uil-calender" :to="{name: 'DailyRosterIndex'}" data-cy="daily-roster-nav"/>
                    </el-menu-item>
                    <el-menu-item>
                        <NavButton :title="$t('label.associate') + ' Management'" icon="uil-user" :to="{name: 'StaffIndex'}" data-cy="da-management-nav"/>
                    </el-menu-item>
                    <!-- <NavButton title="Coaching" icon="uil-trophy" :to="{name: 'CoachingIndex'}"/>                     -->
                    <el-menu-item>
                        <NavButton title="Vehicle Management" icon="uil-truck" :to="{name: 'VehicleIndex'}"/>
                    </el-menu-item>
                    <el-menu-item v-if="permissionInventoryManagement">
                        <NavButton title="Inventory Management" icon="uil-cube" :href="getFullPath('/inventory')"/>
                    </el-menu-item>
                    <el-menu-item>
                        <NavButton title="Tasks & Reports" icon="uil-file-alt" :to="{name: 'ReportsIndex'}"/>
                    </el-menu-item>
                    <!-- <NavButton  v-if="hasMessengerManagement" title="Hera <br>Messenger" icon="uil-comment-alt-dots" :to="{name: 'Messenger'}"/> -->        
                    <el-menu-item>
                        <NavButton title="Hera Messenger" icon="uil uil-comment-alt-dots" :to="{name:onMobileMessengerPermissionNavigation, params:{ isDefaultTabs:true }}">
                            <template #default>
                                <div class="self-center h-16 ml-2"><span v-if="$store.state.messengerNotifications" class="rounded-full bg-pink-500 h-4 text-xs text-white py-0.5 px-1">{{$store.state.messengerNotifications}}</span></div>
                            </template>    
                        </NavButton>
                    </el-menu-item>
                </el-menu-item-group>
                <el-submenu class=" border-t border-b border-gray-600" index="1">
                    <template slot="title">
                        <span class="font-bold">Create</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item v-if="hasCounselingManagement" @click="$emit('click-mobile-nav-import',{type: 'nav', title: 'addCounseling'})" index="1-1">Counseling</el-menu-item>
                        <el-menu-item @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'addDriverIssue'})" index="1-2">{{$t("label.associate")}} Issue</el-menu-item>
                        <el-menu-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && hasDAManagement)" index="1-3" @click="$emit('click-mobile-nav-import',{type: 'nav', title: 'addDriverIssueInfractionType'})"><i class="uil uil-corner-down-right"></i>Violation/Defect</el-menu-item>
                        <el-menu-item @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'addDriverKudo'})" index="1-4">{{$t("label.associate")}} Kudo</el-menu-item>
                        <el-menu-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionAccidents)" index="1-5" @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'addIncident', param: 'incident'})">Incident Record</el-menu-item>
                        <el-menu-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionAccidents)" @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'addIncident', param: 'accident'})" index="1-6"><i class="uil uil-corner-down-right"></i>Accident</el-menu-item>
                        <el-menu-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionInjuries)" @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'addInjury'})" index="1-7">Injury Record</el-menu-item>
                        <el-menu-item @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'Task'})" index="1-8">Task</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu class="border-b border-gray-600" index="2">
                    <template slot="title">
                        <span class="font-bold">Import</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'DailyAmazonImport'})" index="2-1">Daily Roster From Amazon Roster File</el-menu-item>
                        <el-menu-item @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'CustomCSVImport'})" index="2-2">Daily Roster From Custom Spreadsheet</el-menu-item>
                        <el-menu-item @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'DailyDataImport'})" index="2-3">Daily Performance Data</el-menu-item>
                        <el-menu-item v-if="!WEEKLY_IMPORT" @click="$emit('click-mobile-nav-import', {type: 'performanceData'})" index="2-4">Weekly Performance Data</el-menu-item>
                        <el-menu-item v-else @click="$emit('click-mobile-nav-import', {type: 'nav', title: 'WeeklyDataImport'})" index="2-5">Weekly Performance Data</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <!-- <el-submenu class="border-b border-gray-600" index="3">
                    <template slot="title">
                        <span class="font-bold">Reports</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="3-1" @click="mobileHandleCommand('ReportDashboard')">Driver Licenses</el-menu-item>
                        <el-menu-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionAccidents)" index="3-2" @click="mobileHandleCommand('AccidentIndex')">Accidents</el-menu-item>
                        <el-menu-item v-if="!USER_PERMISSIONS || (USER_PERMISSIONS && $store.state.userInfo.permissionInjuries)" index="3-3" @click="mobileHandleCommand('InjuryIndex')">Injuries</el-menu-item>
                        <el-menu-item index="3-3" @click="mobileHandleCommand('netradyneAlert')">Netradyne Alerts</el-menu-item>
                        <el-menu-item index="3-4" @click="mobileHandleCommand('StaffOptedOut')">DAs Opted Out</el-menu-item>
                        <el-menu-item index="3-5" @click="mobileHandleCommand('DaPerformanceReport')">{{$t("label.associate")}} Performance</el-menu-item>
                    </el-menu-item-group>
                </el-submenu> -->
                <el-submenu class="border-b border-gray-600" index="4">
                    <template slot="title">
                        <span class="font-bold">Settings</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item v-if="$store.getters.isSystemAdmin" @click="$emit('click-mobile-nav','SystemNoSelection')" index="4-0">System Admin</el-menu-item>
                        <!-- <el-menu-item v-if="(!USER_PERMISSIONS && $store.getters.isAdmin) || (USER_PERMISSIONS && $store.state.userInfo.permissionFullAccess)" @click="$emit('click-mobile-nav','SettingsAccountDetails')" index="4-1">Account Details</el-menu-item> -->
                        <el-menu-item v-if="(!USER_PERMISSIONS && $store.getters.isAdmin) || (USER_PERMISSIONS && $store.state.userInfo.permissionFullAccess)" @click="$emit('click-mobile-nav','SettingsNoSelection')" index="4-2">Company Settings</el-menu-item>
                        <el-menu-item @click="$emit('click-mobile-nav','userSettings')" index="4-3">User Settings</el-menu-item>
                        <el-menu-item v-if="(!USER_PERMISSIONS && $store.getters.isAdmin) || (USER_PERMISSIONS && $store.state.userInfo.permissionFullAccess)" @click="$emit('click-mobile-nav','Devices')" index="4-4">Manage Devices</el-menu-item>
                        <!-- <el-menu-item v-if="(!USER_PERMISSIONS && $store.getters.isAdmin) || (USER_PERMISSIONS && $store.state.userInfo.permissionFullAccess)" @click="$emit('click-mobile-nav','SettingsUserIndex')" index="4-4">Manage Users</el-menu-item> -->
                        <!-- <el-menu-item @click="$emit('click-mobile-nav','Task')" index="4-5">Manage Tasks</el-menu-item> -->
                    </el-menu-item-group>
                </el-submenu>
                <!-- <el-menu-item @click="$parent.mobileContactUs()" index="5" class="sub-nav-item contact mt-5">
                            <span v-if="intercomMessageCount" class="rounded-full leading-4 bg-pink-500 h-4 text-white mr-2 notification-count md:hidden">{{intercomMessageCount}}</span>
                            <span>Contact Us</span>
                </el-menu-item> -->
                
                <el-menu-item class="sub-nav-item pt-2" @click="$emit('click-mobile-nav','HelpCenter')" target="_blank">
                    <span>Help Center</span>
                </el-menu-item>
                <el-menu-item class="sub-nav-item menu-divider" @click="$emit('click-mobile-nav','referLink')" target="_blank">
                    <span>Refer Hera & Earn $1,000</span>
                </el-menu-item>
                <el-menu-item class="sub-nav-item menu-divider" @click="$emit('click-mobile-nav','ChangePassword')" index="7">   
                    <span>Change Password</span>
                </el-menu-item>
                <el-menu-item class="sub-nav-item" @click="$emit('click-mobile-nav','Logout')" index="8">
                    <span>Logout</span>
                </el-menu-item>
            </div>
        </el-menu>
    </div>
</template>
<script>
import NavButton from '@/components/NavButton'
import Notifications from '@/components/Notifications'
import { mapState, mapGetters } from 'vuex'
import CompanyTime from '@/components/Profile/CompanyTime';

export default {    
    props: ['USER_PERMISSIONS','WEEKLY_IMPORT','hamburgerActive','hasDAManagement','intercomMessageCount','permissionInventoryManagement'],
    // props: {
    //     userInfo: { type: Object, default: {}}
    // },
        
    computed: {
        ...mapState(['userInfo']),
        ...mapGetters(['hasMessengerManagement','hasCounselingManagement']),
        onMobileMessengerPermissionNavigation(){
            if(this.hasMessengerManagement){
                return 'MobileMessengerList'
            }
            return 'Messenger'
        },
    },
    components: {
        NavButton, Notifications, CompanyTime
    },
    mounted(){
        console.log('uinfo', this.userInfo)
    }
}
</script>
<style>
@media only screen and (max-width: 768px) {
    /* mobile nav */
    .mobile-modules .nav-notifications .mr-2{
        margin-left:6px;
    }
    body .mobile-modules .el-menu-item{
        display: flex;
        height: 3em !important;
        padding-left: 15px !important;
    }
    .mobile-modules .el-menu-item .nav-button .nav-title{
        display: flex;
        align-self: center;
        padding: 0 0 .5em .5em;
    }
    .mobile-modules .el-menu-item .nav-button,
    .mobile-modules .el-menu-item i,
    .mobile-modules .main-nav-messenger span{
        color: #3586c9;
        display: flex;
        align-self: center;
    }
    .mobile-modules .el-menu-item .nav-button.router-link-active {
        color: #246dab;
        font-weight: 600;
    }
    .mobile-modules .el-menu-item i{
        font-size: 1.5em;
    }
    .mobile-modules .notification-bubble.text-white{
        color: #fff;
    }
    .el-menu-item.sub-nav-item{
        @apply flex items-center h-10 leading-10 
    }
    .el-submenu .el-menu-item{
        padding:  0 28px 0 28px !important;
    }
    .mobile-modules .mobile-messenger-nav-item .nav-title.leading-5,
    .mobile-modules .mobile-messenger-nav-item .uil-comment-alt-dots.h-5{
        display: none;
    }       
    .mobile-messenger-nav-item{
        height: 2.5em !important;
        margin-bottom: 0.6em;
    }
    .mobile-messenger-nav-item .rounded-full{
        /* padding: 2px 4px 5px 4px; */
    }
    .sub-nav-item{
        display: block;
        padding: 4px;
        margin: 0;
        height: 3em;
    }
    .menu-divider{
        @apply border-t border-gray-400 
    }
}
</style>