<template>
  <div data-cy="ad-subscription-plan-card">
    <div class="flex justify-between">
      <h1>Hera AI</h1>

    </div>
   

    <!-- UPGRADE CARDS -->
    <transition  mode="out-in">
      <div class="mt-4 grid grid-cols-8 grid-flow-row gap-4">
        <div class="col-span-8 p-4 relative border-2 rounded-lg shadow">
          <div v-if="featurePermissions['featureEnabledVehiclePhotoLogsHeraAi']">
            <p class="inline-block text-gray-600 text-base font-bold mr-2">Hera AI Settings</p>
            <el-form label-position="top" size="small">
                <p> Using Hera AI, you can unlock deeper insights from your data. To use this service, 
                    we sometimes need to share portions of your data with selected third party vendors, 
                    so additional terms of use apply. View Hera AI's Terms of Use 
                    <a href="https://www.hera.app/hera-ai-supplemental-terms-of-use" target="_blank"> 
                      <i class="uil uil-external-link-alt"></i></a>
                </p>
                <div class="col-span-12 mt-4 md:col-span-6 lg:col-span-4">
                    <div>Enable Hera AI Features</div>
                    <el-switch
                      v-model="HeraAiFeatures"
                      active-color="#13ce66"
                      @change="toggleHeraAiFeatures"
                    >
                    </el-switch>
                </div>
                <div class="col-span-12 mt-2 my-2 md:col-span-6 lg:col-span-4" v-if="HeraAiFeatures">
                    <div>Enable Hera AI for Vehicle Photo Logs Photo Review</div>
                    <el-switch
                      v-model="HeraAiPhotologReview"
                      active-color="#13ce66"
                      @change="toggleHeraAihotologReview"
                    >
                    </el-switch>
                </div>
            </el-form>
          </div>  
          <div v-else>
            <template>
                <h1>Upgrade Required</h1>
                <p>
                  To enable Hera AI features, you'll need to upgrade your plan. Please contact Hera Support by chatting with us below.
                </p>
                
            </template>
          </div>
        </div>
     </div>
    </transition>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { updateTenantDetail } from '@/store/mutations'
import { getTenantFeaturePermissions } from '@/middlewares/permissions'
import moment from "moment-timezone";
export default {
  name: 'HeraAIIndex',
  data() {
    return {
      HeraAiFeatures: false,
      HeraAiPhotologReview: false,
      featurePermissions:{},
    };
  },
  computed: {
    ...mapState(['userInfo']),
        tenant: {
            get(){
                return this.userInfo.tenant
            },
            set(value){
                this.userInfo.tenant = value
            }
        },
      today() {
      return moment.tz(this.timeZone).format();
    },
    timeZone() {
      return this.getTenantTimeZone();
    },
  },
  beforeMount() {
    this.featurePermissions = getTenantFeaturePermissions()
    this.HeraAiFeatures = this.featurePermissions.permissionHeraAi;
    this.HeraAiPhotologReview = this.featurePermissions.permissionVehiclePhotoLogsHeraAi
  },
  mounted() {
     
  },
  methods: {
    changeToggles(){
      this.featurePermissions.permissionVehiclePhotoLogsHeraAi = this.HeraAiPhotologReview 
      this.featurePermissions.permissionHeraAi = this.HeraAiFeatures 
    },
    async UpdateTenantHeraAI(){
      const updatedAt = this.today
      const input = {
          id: this.tenant.id,
          permissionHeraAi : this.featurePermissions.permissionHeraAi ,
          permissionVehiclePhotoLogsHeraAi : this.featurePermissions.permissionVehiclePhotoLogsHeraAi,
          updatedAt: updatedAt
        }

       await this.api(updateTenantDetail, {input: input}) 
    },
    async toggleHeraAiFeatures() {
      this.HeraAiPhotologReview = this.HeraAiFeatures
      this.changeToggles()
      await this.UpdateTenantHeraAI()
    },
    async toggleHeraAihotologReview() {
      this.changeToggles()
      await this.UpdateTenantHeraAI()
    },
  },
}
</script>

<style scoped>

  .el-select{
        width: 100%;
  }
  .red-text{
    @apply text-red-600;
  }

  .padding-bottom-1{
    @apply pb-3
  }

.child-view {
  transition: all .5s cubic-bezier(.55,0,.1,1);
}
.slide-left-enter, .slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active, .slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.warning-message{
    word-break:break-word;
}
</style>
