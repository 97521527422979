<template>
  <div>
    <el-table-draggable
      handle=".handle"
      :animate="300">
      <el-table
        v-if="tableData"
        :data="tableData"
        style="width: 100%"
        class="align-vertical-padding drawer-table"
        :show-header="showHeader"
        :lazy="true"
        :row-class-name="tableRowClassName"
        empty-text="No Options have been created yet for this Custom List."
      >
        <!-- Option -->
        <el-table-column prop="name" label="OPTION" width="300">
          <template slot-scope="scope">          
            <div class="flex justify-start">
              <div class="flex w-10" v-if="scope.row.canBeReorder">
                <i class="uil uil-bars text-xl flex-auto self-center handle grabbable grabbable-icon"></i>    
              </div>
              <div class="flex flex-initial">
                <template v-if="(userIsSystemAdminOrSupportAdmin && optionCanBeEdited(scope.row)) || (!userIsSystemAdminOrSupportAdmin && scope.row.canBeEdited && optionCanBeEdited(scope.row))">
                  <div v-if="optionsToRemove.map(obj => obj.id).includes(scope.row.id)">
                    <p class="flex-initial fixed-option line-through">
                      {{scope.row.option}}
                    </p>
                  </div>
                  <div v-else class="flex justify-between">
                    <el-input
                      v-model="scope.row.option"
                      :value="scope.row.option"
                      :disabled="optionsToRemove.map(obj => obj.id).includes(scope.row.id)"
                      :class="validationCheckOnOption(scope.row) ? 'is-duplicate-or-empty' : ''">
                    </el-input>
                  </div>
                </template>
                <template v-else>
                  {{ scope.row.option }}
                </template>
                <el-tooltip v-if="!userIsSystemAdminOrSupportAdmin && !scope.row.canBeEdited" class="item" effect="dark" content="This option was created by Hera and cannot be edited. If you need to make changes, please contact Hera Support." placement="right-start">
                  <i class="uil uil-question-circle text-base"></i>
                </el-tooltip>
              </div>  
              <div class="ml-1 flex items-center">
                <el-dropdown v-if="isStatusPillDisplay" @command="handlePillColor" style="width: max-content;">
                  <span class="font-medium font-bold rounded-full px-4 bg-opacity-10 text-sm" :class="[setPillCollor(scope.row.pillColor)]">
                    {{ capitalizeText(scope.row.pillColor || 'blue') }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="pillColor in pillColorsList" :key="pillColor" :command="{color: pillColor, row: scope.row}">{{ capitalizeText(pillColor) }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </template>
        </el-table-column>

      <!-- Status Type -->
      <el-table-column prop="statusType" width="230" v-if="isStatusPillDisplay">
        <template slot="header">
          STATUS TYPE
          <el-tooltip class="item" effect="dark" :placement="isDeviceMobileOnly() ? 'bottom' : 'right-start'">
            <div slot="content" class="custom-tooltip-content-overflow">
                <p>{{ statusTypeTooltipText }}</p>
                <el-button type="text" class="white-button-text">
                  <a href="https://herasolutionsinc.zohodesk.com/portal/en/kb/articles/custom-roster-statuses" target="_blank">
                    Click here to learn more.
                  </a>
                </el-button>
            </div>
            <i class="uil uil-question-circle text-base"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.statusType"
            class="custom-list-select-width">
              <el-option v-for="statusType in statusTypesList" :key="statusType"
                :label="statusType"
                :value="statusType">
              </el-option>
          </el-select>
        </template>
      </el-table-column>

        <!-- Template -->
        <el-table-column prop="template" label="TEMPLATE" width="100" v-if="customList.enabledRecordTemplates">
          <template slot-scope="{ row }">
            <el-button type="text" class="flip_H">
              <i class="text-xl flex-auto self-center"
                :class="row.recordTemplateJson ? 'uil uil-edit': 'uil uil-plus-circle'"
                @click="openUpdateRecordTemplateModal(row)">
              </i>
            </el-button>
          </template>
        </el-table-column>

        <!-- Template -->
        <el-table-column prop="template" label="TEMPLATE" width="100" v-if="customList.enabledRecordTemplates">
          <template slot-scope="{ row }">
            <el-button type="text" class="flip_H">
              <i class="text-xl flex-auto self-center"
                :class="row.recordTemplateJson ? 'uil uil-edit': 'uil uil-plus-circle'"
                @click="openUpdateRecordTemplateModal(row)">
              </i>
            </el-button>
          </template>
        </el-table-column>


        <!-- Used for -->
        <el-table-column prop="usedFor" label="USED FOR" width="170">
          <template slot-scope="scope">
            <span
            :class="optionsToRemove.map(obj => obj.id).includes(scope.row.id) ? 'line-through' : ''"
              v-html="useForText(scope.row)"
            />
          </template>
        </el-table-column>

        <!-- Action -->
        <el-table-column prop="action" label="ACTION" width="75">
          <template slot-scope="scope">
            <el-button v-if="showTrashIcon(scope.row)" 
              type="text" :class="{'is-disabled': checkTrashIconIsDisabled(scope.row)}"> 
              <el-tooltip content="This option cannot be deleted because it is the default option" placement="top" v-if="checkIsDefaultForSections(scope.row)">
                <i class="uil uil-trash-alt text-xl flex-auto self-center"
                  @click="trash(scope.row, scope.$index)">
                </i>
              </el-tooltip>
              <i v-else class="uil uil-trash-alt text-xl flex-auto self-center"
                @click="trash(scope.row, scope.$index)">
              </i>
            </el-button>

            <el-button type="text" v-else-if="optionsToRemove.map(obj => obj.id).includes(scope.row.id)" class="flip_H">
              <i class="uil uil-redo text-xl flex-auto self-center"
                @click="trash(scope.row, scope.$index)">
              </i>
            </el-button>
            
            <el-button v-else-if="showReassignIcon(scope.row)" type="text">
              <i class="uil uil-repeat text-xl flex-auto self-center"
                @click="
                  reassignItems(
                    type,
                    scope.row.option,
                    scope.row.usedFor,
                    scope.row,
                    scope.row.daysCount
                  )">
              </i>
            </el-button>
          </template>
        </el-table-column>

        <!-- Status -->
        <el-table-column prop="isEnabled" label="STATUS" width="100">
          <template slot-scope="scope">
            <div class="flex cursor-pointer">
              <span class="font-bold rounded-full bg-opacity-10 px-3 text-xs max-w-full flex-initial hover:bg-opacity-100 hover:text-white" :class="setClassStatus(scope.row.isEnabled)" @click="changeStatus(scope.row)">
                {{ `${scope.row.isEnabled ? 'Enabled' : 'Disabled' }` }}
              </span>
            </div>
          </template>
        </el-table-column>

      </el-table>
    </el-table-draggable>

    <drawer-record-template 
      :form="formRecordTemplate"
      @update-record-template="onUpdateRecordTemplate"
      @close-drawer="resetFormRecordTemplate"
    />
  </div>
</template>

<script>
import ElTableDraggable from "element-ui-el-table-draggable";
import DrawerRecordTemplate from "./DrawerRecordTemplate.vue";
import {isOptionCustomListLinkedToOtherRecords} from '../utils';
import { mapGetters } from 'vuex';
import { pillColors, statusTypes, recordTemplates } from '../constants'

export default {
  components: {
    ElTableDraggable,
    DrawerRecordTemplate
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    listDisplay: {
      type: String,
      default: "",
    },
    listName: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    reassignedOptions: {
      type: Array,
      default: () => [],
    },
    customList: {
      type: Object,
      default: () => ({}),
    }
  },

  data() {
    return {
      optionsToRemove: [],
      formRecordTemplate: {
        visible: false,
        title: '',
        subtitle: '',
        templates: {}
      },
    };
  },

  computed: {
    ...mapGetters([
      'isSystemAdmin',
      'hasLogguedSupportAdministrator',
    ]),
    isStatusPillDisplay() {
      return (this.listDisplay === 'Status Pill')
    },

    fixedCheckboxDefault() {
      return this.type && (this.type === 'route-status' || this.type === 'vehicle-role-for-route')
    },
    duplicateOptions() {
      let duplicateOptionNames = this.tableData.filter((value, index, self) => index !== self.findIndex(t => value.option.toLowerCase().trim()  === t.option.toLowerCase().trim()))
      let longOptionNames = this.tableData.filter((value, index, self) => value.option.toLowerCase().trim().length > 30 || value.option.includes(';'))
      let semiColonIncludeOptionsName = this.tableData.filter((value, index, self) =>  value.option.includes(';'))
      return duplicateOptionNames.concat(longOptionNames,semiColonIncludeOptionsName).map(obj => obj.option.trim())
    },
    showReasingIcon() {
      return !(this.optionsToRemove.length == this.tableData.length -1)
    },
    userIsSystemAdminOrSupportAdmin() {
      return (this.isSystemAdmin || this.hasLogguedSupportAdministrator);
    },
    pillColorsList() {
      return pillColors
    },
    statusTypesList() {
      return statusTypes
    },
    statusTypeTooltipText() {
      return `For each ${this.listName}, you can decide if the status is "Active" or "Inactive", which will dictate how the status is treated in Hera. Additionally, "Positive" statuses may offer to create Kudos in Hera, while "Negative" statuses may offer to create Issues.`
    }
  },

  methods: {
    showTrashIcon(row) {
      return ((this.userIsSystemAdminOrSupportAdmin && this.optionCanBeDeleted(row)) || (!this.userIsSystemAdminOrSupportAdmin && row.canBeEdited && this.optionCanBeDeleted(row) && row.canBeDeleted))
    },
    showReassignIcon(row) {
      return (this.userIsSystemAdminOrSupportAdmin && this.optionCanBeReAssigned(row)) || (!this.userIsSystemAdminOrSupportAdmin && row.canBeEdited && this.optionCanBeReAssigned(row))
    },
    checkIsDefaultForSections(row) {
      return row.isDefaultForSections?.length
    },
    checkTrashIconIsDisabled(row) {
      const isDefaultForSections = this.checkIsDefaultForSections(row)
      if (isDefaultForSections) return true
      return this.reassignedOptions.includes(row.id)
    },
    handlePillColor(payload) {
      const { row, color } = payload;
      this.tableData.map((option) => {
        if (option.id == row.id) {
          option.pillColor = color;
        }
      });
    },
    useForText(row) {
      const customListTypes = {
        'incident-type' : () => {
          let accidents = row.accidents?.items.length || 0;
          return `${accidents} ${this.pluralize(accidents, 'Incident', 'Incidents')}`
        },

        'vehicle-role-for-route': () => {
          return `${row.usedFor} ${this.pluralize(row.usedFor, 'Vehicle Assignment', 'Vehicle Assignments')}`
        },

        'parking-space': () => {
          let vehicleParkingSpace = row.parkingSpace?.items.length || 0;
          let routeParkingSpace = row.routeParkingSpace?.items.length || 0;
          let replaceByRouteParkingSpace = row.replaceByRouteParkingSpace?.items.length || 0;
          // let rosteredVehicleParkingSpace = row.rosteredVehicleParkingSpace?.items.length || 0;
          let rosteredVehicle = routeParkingSpace + replaceByRouteParkingSpace 
          return `${rosteredVehicle} ${this.pluralize(rosteredVehicle, 'Rostered Vehicle', 'Rostered Vehicles')} • ${vehicleParkingSpace} ${this.pluralize(vehicleParkingSpace, 'Vehicle', 'Vehicles')}`
        },

        'vehicle-type': () => {
          let vehicles = row.vehicles?.items.length || 0;
          let associates = row.associates ? row.associates.items.length : 0
          let checklist = row.checklist
          let checklistTemplates = row.checklistTemplates
          const part = [
            `${vehicles} ${this.pluralize(vehicles, 'Vehicle', 'Vehicles')}`,
            `${associates} ${this.pluralize(associates, 'Associate', 'Associates')}`,
            `${checklist} ${this.pluralize(checklist, 'Checklist', 'Checklists')}`,
            `${checklistTemplates} ${this.pluralize(checklistTemplates, 'Checklist Template', 'Checklist Templates')}`,
          ]
          return part.join(' •<br/>')
        },
        
        'counseling-severity': () => {
          const count = row.counselings?.items.length || 0;
          return `${count} ${this.pluralize(count, 'Counseling', 'Counselings')}`;
        },

        'issue-type': () => {
          const count = row.issues?.items.length || 0;
          return `${count} ${this.pluralize(count, 'Issue', 'Issues')}`;
        },

        'kudo-type': () => {
          const count = row.kudos?.items.length || 0;
          return `${count} ${this.pluralize(count, 'Kudo', 'Kudos')}`;
        },

        'vehicle-company': () => {
          const count = row.companies?.items.length || 0;
          return `${count} ${this.pluralize(count, 'Vehicle', 'Vehicles')}`;
        },

        'roster-status': () => {
          let routes = row.routes?.items.length || 0;
          let routeHelperStatus = row.routeHelperStatus?.items.length || 0;
          let replaceByRoutes = row.replaceByRoutes?.items.length || 0;
          let total = routes + routeHelperStatus + replaceByRoutes;
          return `${total} ${this.pluralize(total, 'Rostered Route', 'Rostered Routes')}`;
        },

        'photo-log': () => {
          let total = row.documents?.items.length || 0;
          return `${total} ${this.pluralize(total, 'Associate Link', 'Associate Links')}`;
        },

        'counseling-type': () => {
          const count = row.counselingsByType?.items.length || 0;
          return `${count} ${this.pluralize(count, 'Counseling', 'Counselings')}`;
        },

        'another-type': () => {
          return `${row.usedFor} ${this.pluralize(row.usedFor, 'Route', 'Routes')} • 0 ${this.pluralize(0, 'Day', 'Days')}`
        },
      }

      if(customListTypes[this.type]) return customListTypes[this.type]()
      else return customListTypes['another-type']()

      
    },

    optionCanBeEdited(row) {
      const customListTypes = {
        'incident-type' : () => {
          let accidents = row.accidents ? row.accidents?.items.length : 0
          return accidents === 0
        },

        'vehicle-role-for-route': () => {
          return row.usedFor === 0;
        },

        'parking-space': () => {
          let vehicleParkingSpace = row.parkingSpace ? row.parkingSpace.items.length : 0
          let routeParkingSpace = row.routeParkingSpace ? row.routeParkingSpace.items.length : 0
          let replaceByRouteParkingSpace = row.replaceByRouteParkingSpace ? row.replaceByRouteParkingSpace.items.length : 0
          let rosteredVehicleParkingSpace = row.rosteredVehicleParkingSpace ? row.rosteredVehicleParkingSpace.items.length : 0   
          let rosteredVehicle = routeParkingSpace + replaceByRouteParkingSpace + rosteredVehicleParkingSpace
          return (vehicleParkingSpace === 0 && rosteredVehicle === 0)
        },

        'vehicle-type': () => {
          let vehicles = row.vehicles ? row.vehicles.items.length : 0
          let associates = row.associates ? row.associates.items.length : 0
          return (vehicles === 0 && associates === 0)
        },
        
        'counseling-severity': () => {
          return (row.counselings?.items.length === 0);
        },

        'counseling-type': () => {
          return (row.counselingsByType?.items.length === 0);
        },

        'issue-type': () => {
          return (row.issues?.items.length === 0);
        },

        'kudo-type': () => {
          return (row.kudos?.items.length === 0);
        },

        'vehicle-company': () => {
          return (row.companies?.items.length === 0);
        },

        'roster-status': () => {
          let routes = row.routes?.items.length || 0;
          let routeHelperStatus = row.routeHelperStatus?.items.length || 0;
          let replaceByRoutes =row.replaceByRoutes?.items.length || 0;
          let total = routes + routeHelperStatus + replaceByRoutes;
          return (total === 0);
        },
        'photo-log': () => {
          let total = row.documents?.items.length || 0;
          return total === 0;
        },
        'another-type': () => {
          return false
        },
      }

      if(customListTypes[this.type]) return customListTypes[this.type]();
      else return customListTypes['another-type']();
    },
    optionCanBeDeleted(row) {
      const customListTypes = {
        'incident-type' : () => {
          let accidents = row.accidents ? row.accidents.items.length : 0
          return accidents === 0;
        },

        'vehicle-role-for-route': () => {
          return row.usedFor === 0;
        },

        'parking-space': () => {
          let vehicleParkingSpace = row.parkingSpace ? row.parkingSpace.items.length : 0
          let routeParkingSpace = row.routeParkingSpace ? row.routeParkingSpace.items.length : 0
          let replaceByRouteParkingSpace = row.replaceByRouteParkingSpace ? row.replaceByRouteParkingSpace.items.length : 0
          let rosteredVehicleParkingSpace = row.rosteredVehicleParkingSpace ? row.rosteredVehicleParkingSpace.items.length : 0
          let rosteredVehicle = routeParkingSpace + replaceByRouteParkingSpace + rosteredVehicleParkingSpace       
          return (vehicleParkingSpace === 0 && rosteredVehicle === 0);
        },

        'vehicle-type': () => {
          let vehicles = row.vehicles ? row.vehicles.items.length : 0
          let associates = row.associates ? row.associates.items.length : 0
          let checklistTemplates = row.checklistTemplates
          let checklist = row.checklist
          return (vehicles === 0 && associates === 0 && checklist === 0 && checklistTemplates === 0);
        },
        
        'counseling-severity': () => {
          return (row.counselings?.items.length === 0);
        },

        'counseling-type': () => {
          return (row.counselingsByType?.items.length === 0);
        },

        'issue-type': () => {
          return (row.issues?.items.length === 0);
        },

        'kudo-type': () => {
          return (row.kudos?.items.length === 0);
        },

        'vehicle-company': () => {
          return (row.companies?.items.length === 0);
        },

        'roster-status': () => {
          let routes = row.routes?.items.length || 0;
          let routeHelperStatus = row.routeHelperStatus?.items.length || 0;
          let replaceByRoutes = row.replaceByRoutes?.items.length || 0;
          let total = routes + routeHelperStatus + replaceByRoutes;
          return (total === 0);
        },
        'photo-log': () => {
          let total = row.documents?.items.length || 0;
          return total === 0;
        },
        'another-type': () => {
          return false
        },
      }

      if(customListTypes[this.type]) return customListTypes[this.type]()
      else return customListTypes['another-type']()
    },
    optionCanBeReAssigned(row) {
      let result = null
      if (this.type === 'incident-type') {
        let accidents = row.accidents ? row.accidents.items.length : 0
        result =  accidents > 0 
      } else if (this.type === 'vehicle-role-for-route') {
        result = row.usedFor > 0
      } else if (this.type === 'parking-space') {
        let vehicleParkingSpace = row.parkingSpace ? row.parkingSpace.items.length : 0
        let routeParkingSpace = row.routeParkingSpace ? row.routeParkingSpace.items.length : 0
        let replaceByRouteParkingSpace = row.replaceByRouteParkingSpace ? row.replaceByRouteParkingSpace.items.length : 0
        let rosteredVehicleParkingSpace = row.rosteredVehicleParkingSpace ? row.rosteredVehicleParkingSpace.items.length : 0  
        let rosteredVehicle = routeParkingSpace + replaceByRouteParkingSpace + rosteredVehicleParkingSpace      
        result = (vehicleParkingSpace > 0 || rosteredVehicle > 0) 
      } else if (this.type === 'vehicle-type') {
        let vehicles = row.vehicles ? row.vehicles.items.length : 0
        let associates = row.associates ? row.associates.items.length : 0
        result = (vehicles > 0 || associates > 0)
      } else if (this.type === 'counseling-severity') {
        let counselings = row.counselings ? row.counselings.items.length : 0
        result =  counselings > 0 
      } else if (this.type === 'counseling-type') {
        let counselingsByType = row.counselingsByType ? row.counselingsByType.items.length : 0
        result =  counselingsByType > 0 
      } else if (this.type === 'issue-type') {
        let issues = row.issues ? row.issues.items.length : 0
        result =  issues > 0 
      } else if (this.type === 'kudo-type') {
        let kudos = row.kudos ? row.kudos.items.length : 0
        result =  kudos > 0 
      } else if (this.type === 'vehicle-company') {
        let companies = row.companies ? row.companies.items.length : 0
        result =  companies > 0 
      } else if (this.type === 'roster-status') {
        let routes = row.routes ? row.routes.items.length : 0
        let routeHelperStatus = row.routeHelperStatus ? row.routeHelperStatus?.items.length : 0
        let replaceByRoutes = row.replaceByRoutes ? row.replaceByRoutes?.items.length : 0
        result = (routes > 0 || routeHelperStatus > 0 || replaceByRoutes > 0)
      } else if(this.type === 'photo-log'){
        let documents = row.documents ? row.documents.items.length : 0
        return documents > 0
      } else {
        result = false
      }
      return result && this.showReasingIcon 
    },
    defaulCheck(value) {
      this.tableData.map((option) => {
        if (option.id != value.id) {
          option.default = false;
        }
      });
    },
    openUpdateRecordTemplateModal(item) {
      const recordTemplateItems = recordTemplates[this.type]
      const recordTemplateJson = JSON.parse(item.recordTemplateJson)      
      this.formRecordTemplate.recordTemplateJson = item.recordTemplateJson
      this.formRecordTemplate.templates = recordTemplateItems.map((template) => ({
        ...template,
        value: recordTemplateJson ? recordTemplateJson[template.prop] : null,
      }))
      this.formRecordTemplate.id = item.id
      this.formRecordTemplate.visible = true
      this.formRecordTemplate.title = `${recordTemplateJson ? 'Edit' : 'Create'} Record Template for ${item.option}`
      this.formRecordTemplate.subtitle = `Custom List: ${this.listName}`
    },
    onUpdateRecordTemplate(recordTemplate = null){
      this.tableData.map(option => {
        if (option.id == this.formRecordTemplate.id) {
          option.recordTemplateJson = recordTemplate
        }
        return option
      })
      this.resetFormRecordTemplate()
    },
    onCloseDrawer(){
      this.resetFormRecordTemplate()
    },
    resetFormRecordTemplate() {
      this.formRecordTemplate.id = null
      this.formRecordTemplate.templates = []
      this.formRecordTemplate.recordTemplateJson = null
      this.formRecordTemplate.visible = false
      this.formRecordTemplate.title = null
      this.formRecordTemplate.subtitle = null 
    },
    async trash(option, index) {  
      if (option.id) {
        const trashIconIsDisabled = this.checkTrashIconIsDisabled(option)
        if (trashIconIsDisabled) return;
        
        let optionExists = this.optionsToRemove.find(opt => opt.id === option.id)
        if (optionExists) {
          let optionExistsIndex = this.optionsToRemove.findIndex(opt => opt.id === option.id)
          this.tableData[index].edited = true;
          this.optionsToRemove.splice(optionExistsIndex, 1);
          this.$emit("removeOptions", this.optionsToRemove);
        } else {
          const hasLinkedData = await isOptionCustomListLinkedToOtherRecords(this.type, option);
          if (hasLinkedData) {
            const error = 'This Custom List option is linked to other records and cannot be deleted. Please refresh your browser window to see the latest info.';
            return this.displayUserError(error);
          };
          this.tableData[index].edited = false;
          this.optionsToRemove.push(option);
          this.$emit("removeOptions", this.optionsToRemove);
        }
        if (option.default) {
          option.default = false;
          this.tableData.filter(opt => !this.optionsToRemove.map(optn => optn.id).includes(opt.id)).map((opt, index) => {
              if (index === 0) {
                opt.default = true
              }
          });
        }
      } else {
        this.tableData.splice(index, 1);
      }
    },
    reassignItems(type, name, userFor, row, daysCount) {
      this.$emit("visibilityTableDraggable", true);
      this.$emit("tableSelected", "draggable");
      this.$emit("dataDialog", row);
    },
    tableRowClassName({ row }) {
      if (this.optionsToRemove.map(obj => obj.id).includes(row.id)) {
        return "table-row-disabled";
      }
      if(!row.isEnabled){
        return "transition-opacity duration-300 opacity-75";
      }
    },

    changeStatus(row){
      const findResult = this.tableData.find(option => option.id === row.id)
      if(findResult.isDefaultForSections && findResult.isDefaultForSections.length){
        this.$emit("cannotBeDisabled", true, row);
      }else{
        row.isEnabled = !row.isEnabled;
        this.$emit("cannotBeDisabled", false, row);
      }
     
    },

    setClassStatus(isEnabled){
      return {
        'bg-green-500 text-green-600' : isEnabled,
        'bg-red-500 text-red-600' : !isEnabled,
      }
    },

    validationCheckOnOption(item) {
      return item.isCustom && (this.duplicateOptions.includes(item.option.trim()) || item.option.trim() === '') ? true : false
    },
  },
};
</script>

<style lang="scss">

.is-duplicate-or-empty .el-input__inner {
  border-color: #F56C6C !important;
}

.drawer-style {
  .el-drawer__header {
    @apply bg-brand p-4 mb-0;
    color: #fff;
  }
}
</style>
<style scoped>
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable::before {
  margin: 0px !important;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
<style>
.el-table__empty-text{
  width: 80% !important;
}

.fixed-option {
  padding: 0 4px 0px 0px;
}

.drawer-table .table-row-disabled {
  background: rgb(254 202 202) !important;
  cursor: not-allowed !important;
  pointer-events: none;
}

.flip_H{ 
  transform: scale(-1, 1); 
  text-align: end;
  pointer-events: auto;
}

.white-button-text {
  color: #fff !important;
}
</style>