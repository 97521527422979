<template>
  <div :class="inTable ? 'custom-table--card' : 'custom-table'">
    <div class="block md:flex py-5" v-if="pagination">
      <div class="pl-3 self-start mb-5 md:mb-0 text-center md:text-left">
        <span v-if="paginationRange!=='0'">Showing {{paginationRange}} of {{filteredRecordsCount}} {{paginationFooter}}</span>
      </div>
      <el-pagination
        :class="paginationClass"
        background
        :hide-on-single-page="true"
        :current-page.sync="paginationPage"
        @size-change="paginationPageSizeChange"
        @current-change="paginationPageChange"
        :page-sizes="paginationPageSizes"
        :page-size="paginationPageSize"
        layout="sizes, prev, pager, next"
        :total="filteredRecordsCount">
      </el-pagination>
    </div>
    <div class="relative" :class="{'custom-table-draggable': draggable}" :ref="forceUpdate">
      <el-table
        v-loading="loading"
        ref="table"
        :empty-text="emptyText"
        header-cell-class-name="table-header-cell"
        :data="visibleData"
        :default-sort = "defaultSort"
        :tree-props="childs"
        row-key="id"
        row-class-name="table-row"
        @sort-change="sortChange"
        :class="classTable"
      >
        <template v-if="withSelect">
          <!-- THIS COLUMN GETS RENDERED FOR TABLE SELECTION OF THIS COMPONENT -->
          <el-table-column width="100">
            <template #header>
              <el-dropdown trigger="click" @command="handleSelectionCommand">
                <section class="flex cursor-pointer">
                  <span :class="[
                    'px-2 border-rounded',
                    (selectedRowsCount > 0)? 'bg-blue-700 text-white': 'bg-gray-200',
                    ]"
                  >
                    {{ selectedRowsCount }} <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                </section>
              
                <el-dropdown-menu v-if="!useMultiPageSelect" slot="dropdown">
                  <el-dropdown-item>
                    <span class="font-bold text-xs uppercase contextmenu__title">{{ selectedRowsCount }} SELECTED {{ (selectedRowsCount === 1)? selectionEntityLabels[0]: selectionEntityLabels[1] }}</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="!areAllRowsSelected" command="select_all">Select All</el-dropdown-item>
                  <el-dropdown-item v-if="selectedRowsCount > 0" command="select_none">Select none (clear selection)</el-dropdown-item>
                  <!-- CUSTOM SELECTION ACTIONS -->
                  <el-dropdown-item
                    v-for="(action, index) in selectionCustomActionsToDisplay"
                    :key="action.command"
                    :divided="index === 0"
                    :command="action.command"
                  >
                    {{ action.label }}
                  </el-dropdown-item>
                  <!-- DELETE SECTION -->
                  <el-dropdown-item v-if="shouldDisplaySelectionDeleteAction && selectionDeleteHandler && (selectedRowsCount > 0)" command="delete_selected" divided>Delete Selected</el-dropdown-item>
                  <el-dropdown-item v-if="selectedRowsCount > 0" command="export_selected" divided>Export {{ selectedRowsCount }} Selected Records</el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu v-else slot="dropdown">
                  <section class="px-5 pb-3 border-b-1 border-grey">
                    <span  class="font-bold text-xs uppercase contextmenu__title">{{ selectedRowsArray.length }} SELECTED {{ (selectedRowsCount === 1)? selectionEntityLabels[0]: selectionEntityLabels[1] }} 
                      <span v-if="selectedRowsArray.length < selectedRowsCount">ON THIS PAGE, {{ selectedRowsCount }} ACROSS ALL PAGES</span>
                    </span>
                  </section>
                  <el-dropdown-item v-if="selectedRowsArray.length < data.length" command="select_all_on_page"> {{ selectAllOnPageText }}</el-dropdown-item>
                  <el-dropdown-item v-if="selectedRowsCount < filteredRecordsCount" command="select_all">Select All {{ filteredRecordsCount }} {{ capitalizedSelectionEntity }} 
                    <span v-if="totalPages>1">across {{ totalPages }} pages</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="selectedRowsCount > 0" command="select_none">Select none (clear selection)</el-dropdown-item>
                  <!-- CUSTOM SELECTION ACTIONS -->
                  <el-dropdown-item
                    v-for="(action, index) in selectionCustomActionsToDisplay"
                    :key="action.command"
                    :divided="index === 0"
                    :command="action.command"
                  >
                    {{ action.label }}
                  </el-dropdown-item>
                  <!-- DELETE SECTION -->
                  <el-dropdown-item v-if="shouldDisplaySelectionDeleteAction && selectionDeleteHandler && (selectedRowsCount > 0)" command="delete_selected" divided>Delete Selected</el-dropdown-item>
                  <el-dropdown-item v-if="selectedRowsCount > 0" command="export_selected" divided>Export {{ selectedRowsCount }} Selected Records</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>

            <template slot-scope="{row}">
              <div class="ml-3">
                <el-checkbox @change="(e)=>rowSelection(row, e)" v-model="selectedRowsIDsMap[row.id]" v-if="!selectionRowDisabledResolver(row)" />
                <el-checkbox v-else disabled aria-disabled="true"/>
              </div>
            </template>
          </el-table-column>
        </template>

        <template v-for="(column, colIndex) in columns">
          <el-table-column
            v-if="!column.type"
            :min-width="column.width"
            :key="column.name"
            :fixed="column.fixed"
            :sortable="column.sortable"
            :sort-method="column.sortMethod"
            :render-header="column.renderHeader"
            :prop="column.col"
          >
            <template slot="header">
              <span class="inline-block leading-normal align-left" :class="[column.col+'-class']">
                <slot name="column-header" :columnHeader="column.name">
                  {{ column.name }}
                </slot>
              </span>
            </template>

            <template slot-scope="{ row, $index: rowIndex }">
                <span :id="row.id ? row.id + colIndex : row[column.col] + colIndex" :class="[
                  column.name ? 'align-left' : 'pl-2',
                  showExpandCollapseColumn ? 'inline-block truncate' : 'inline-block',column.col+'-class'
                ]" :style="{'max-width': getMaxWidth}">
                <slot :name="column.col" v-bind="row">
                  <div v-if="isStatusField(column.col)" class="inline-flex">
                    <CellToolTip
                      v-if="getValueOfRowProp(column.col, row)"
                      :value="getValueOfRowProp(column.col, row)"
                    >
                      <div slot-scope="{textValue}" class="flex">
                        <span
                          class="font-bold rounded-full bg-opacity-10 px-3 text-xs max-w-full flex-initial"
                          :class="setClassStatus(getValueOfRowProp(column.col, row))"
                        >{{textValue}}</span>
                      </div>
                    </CellToolTip>
                    <span v-else>—</span>
                  </div>
                  <CellToolTip v-else
                    :value="row[column.col]" 
                    :maxLength="showExpandCollapseColumn ? getWidth(colIndex, rowIndex, row,column) : 700"
                    :kind="column.kind">
                    <div slot="tooltip" slot-scope="{ expandedValue, contractedValue }">
                        <template v-if="expandedValue || contractedValue">{{ expandedValue ? expandedValue : contractedValue }}</template> 
                        <slot :name="'custom-tooltip-'+column.col" v-else></slot>
                    </div>
                  </CellToolTip>
                </slot> 
              </span>
            </template>
          </el-table-column>

          <el-table-column
            v-else
            :type="column.type"
            :min-width="column.width"
            :key="`v-else-${column.name}`"
            :fixed="column.fixed"
            :sortable="column.sortable"
            :sort-method="column.sortMethod"
            :selectable="column.selectable"
          ></el-table-column>

        </template> 
        <el-table-column align="right" :width="rowActionButton ? '95':'50'"  fixed="right">
          <template v-slot:header>
            <el-dropdown
              v-if="haveTableMenuAction"
              placement="bottom-end"
              class="mt-1 fix-height"
              :data-cy="'three_dot_button'"
              trigger="hover"
              :hide-on-click="true"
              @command="(command) => handleCommand(command)"
            >
              <span @click="handleCustomMenuClick" @mouseenter="handleCustomMenuClick">
                <ContextMenuHeaderIcon
                  :class="rowActionButton ? 'context-menu-header-hover -mr-0.75' : 'context-menu-header-hover ml-0.75 mr-2'"
                />
              </span>
              <template slot="dropdown">
                <el-dropdown-menu id="menu" class="action-dropdown-menu">
                  <div v-if="isExpandedCol" class="el-dropdown-menu__item" @click="handleColumn">
                    <span>{{ isExpandedComputed }}</span>
                    <i
                      :class="isExpandedComputed === 'Expand Row Height' ? 'uil-arrows-shrink-v' : 'uil-compress-lines pl-1'"
                      style="font-size:18px"
                    ></i>
                  </div>
                  <div v-if="canSendMessage" class="el-dropdown-menu__item" @click="handleSendMessage">
                    <span>{{ labelOptionSendMessage }}</span>
                  </div>
                  <el-dropdown-item
                    v-for="option in optionsContextHeader"
                    :key="option.label"
                    :command="{ data: null, action: option.action }"
                    :divided="option.divided"
                  >
                    <slot :name="option.label" :scope="{ option }">{{ option.label }}</slot>
                  </el-dropdown-item>
                  <template v-if="showDefaultExportOptions" >
                    <div v-if="!hideOptionFilteredExport" class="el-dropdown-menu__item" @click="handleExport('filtered')">
                      <span>Export {{ filteredRecordsCount }} Filtered Records</span>
                    </div>
                    <div v-if="totalRecords > 0" class="el-dropdown-menu__item" @click="handleExport('All')">
                      <span>Export All {{ totalRecords }} Records</span>
                    </div>
                  </template>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
          <template slot-scope="{ row }" >
            <i class="row-action-menu"></i>
            <div class="flex justify-around items-center">
              <slot name="row-action-menu" v-bind="row"></slot>  
              <el-dropdown
                v-if="getOptionsContextRow(row).length || optionsContextSubRow.length"
                data-cy="three_dot_button_row"
                class="mt-1 fix-height"
                trigger="click"
                @command="handleCommand">
                <template v-if="childs.children">
                  <!-- Group row -->
                  <template v-if="row[childs.children]">
                    <span class="context-menu" :class="getOptionsContextRow(row).length ? '': 'hidden'">
                      <i class="uil uil-ellipsis-h"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown-menu" >
                      <el-dropdown-item
                        v-for="option in optionsContextRow" 
                        :key="option.label"
                        :divided="option.divided"
                        :command="{ row, action: option.action }"
                      > {{ option.label }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                  <template v-else>
                    <!--Sub Row -->                  
                    <span class="context-menu" :class="optionsContextSubRow.length ? '': 'hidden'">
                      <i class="uil uil-ellipsis-h"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown-menu" >
                      <el-dropdown-item 
                        v-for="option in optionsContextSubRow" 
                        :key="option.label" 
                        :divided="option.divided"
                        :command="{ row, action: option.action }">
                        {{ option.label }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </template>
                <template v-else >
                  <!-- Row -->
                  <span v-if="getOptionsContextRow(row).length" class="context-menu"><i class="uil uil-ellipsis-h"></i></span>
                  <el-dropdown-menu slot="dropdown" class="action-dropdown-menu" >
                    <el-dropdown-item 
                      v-for="option in getOptionsContextRow(row)"
                      :key="option.label" 
                      :divided="option.divided"
                      :command="{ row, action: option.dynamicAction ? option.dynamicAction(row) : option.action }">
                      {{ option.dynamicLabel ? option.dynamicLabel(row) : option.label }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

              <slot name="custom-dropdown" v-bind="row"></slot> 
            </div>           
          </template>
        </el-table-column>
      </el-table>
     
      <template v-if="haveScroll && data.length">
        <div
          v-show="canScrollToLeft"
          class="absolute top-0 h-full px-2 text-white text-2xl bg-brand-50 bg-opacity-20 overflow-hidden left-0"
          @click.prevent="swipeLeft">  
          <div v-if="filterRecords <= 2" class="flex items-center h-full">
            <span><i class="el-icon-arrow-left"></i></span>
          </div>
          <div v-else>
            <div class="pt-45" v-for="i in filterRecordsNew" :key="i">
              <span><i class="el-icon-arrow-left"></i></span>
            </div>
          </div>
        </div>
        <div
          v-show="canScrollToRight"
          class="absolute top-0 h-full px-2 text-white text-2xl bg-brand-50 bg-opacity-20 overflow-hidden"
          :class="[ !haveTableMenuAction ? 'right-0':'right-5', 
          haveTableMenuAction && existFixedInTable ? 'right-9': 'right-5',
          haveTableMenuAction && rowActionButton ? 'right-9': 'right-5']" 
          @click.prevent="swipeRight">
          <div v-if="filterRecords <= 2" class="flex items-center h-full">
            <span><i class="el-icon-arrow-right"></i></span>
          </div>
          <div v-else>
            <div class="pt-45" v-for="i in filterRecordsNew" :key="i">
              <span><i class="el-icon-arrow-right"></i></span>
            </div>
          </div>
        </div>
      </template>

    </div>
    <div :class="showFooterText ? 'p-2 flex justify-between' : 'flex justify-between'">
      <span v-if="showFooterText" data-cy="filter-records">Showing {{ filterRecords }} of {{ totalRecordsFooter !== -1 ? totalRecordsFooter : filteredRecordsCount }} {{ footerTable }}</span>
    </div>

    <el-dialog
      v-if="isSelectionDeleteModalOpen"
      width="30%"
      title="Are you sure?"
      :visible.sync="isSelectionDeleteModalOpen"
    >
      <CommonDeleteDialog
        @delete-action="commitSelectionDelete"
        @close-modal="isSelectionDeleteModalOpen = false"
      >
        <template v-slot:customText>
          <slot name="customText">
            <slot></slot>
          </slot>
        </template>
      </CommonDeleteDialog>
    </el-dialog>
    <div v-if="pagination" class="block md:flex py-5">
      <div class="pl-3 self-start mb-5 md:mb-0 text-center md:text-left">
        <span v-if="paginationRange!=='0'">Showing {{paginationRange}} of {{filteredRecordsCount}} {{paginationFooter}}</span>
      </div>
      <el-pagination
        :class="paginationClass"
        background
        :hide-on-single-page="true"
        :current-page.sync="paginationPage"
        @size-change="paginationPageSizeChange"
        @current-change="paginationPageChange"
        :page-sizes="paginationPageSizes"
        :page-size="paginationPageSize"
        layout="sizes, prev, pager, next"
        :total="filteredRecordsCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import ContextMenu from "@/components/TableCard/ContextMenu";
import ContextMenuSelect from "@/components/TableCard/ContextMenuSelect";
import ContextMenuHeaderIcon from '@/components/ContextMenuHeaderIcon';
import CellToolTip from '@/components/CellToolTip';
import { parseCounselingStatusValueToLabel } from "@/views/Counseling/utils";
import CommonDeleteDialog from '@/components/CommonDeleteDialog';
import Sortable from 'sortablejs/Sortable.js';

export default {

  components: {
    ContextMenuSelect,
    ContextMenuHeaderIcon,
    CellToolTip,
    CommonDeleteDialog,
  },

  props: {
    selectionEntityLabels: {
      type: Array,
      default: () => (['', '']),
    },
    selectionDisplayDeleteActionResolver: {
      type: Function,
      default: () => (true),
    },
    selectionRowDisabledResolver: {
      type: Function,
      default: () => (false), // All rows enabled by default
    },
    selectionDeleteHandler: {
      type: Function,
      default: null,
    },
    selectionCustomActions: {
      type: Array,
      required: false,
      default: () => ([]),
    },

    useMultiPageSelect:{
      type: Boolean,
      required: false,
      default: false
    },
    selectAllHandler: {
      type: Function,
      required: false,
      default: () => []
    },
    pageSize: {
      type: Number,
      required: false,
      default: 1
    },
    providedSelectedRows: {
      type: [Object, Number],
      required: false,
      default: () => {}
    },

    showFooterText: {
      type: Boolean,
      required: false,
      default: true
    },
    childs: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    defaultSort: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    // configuration prop
    columns: {
      type: [Array, Function],
      required: true,
      default: []
    },
    // data prop 
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    allRecordsSelected: {
      type: Array,
      required: false,
      default: () => []
    },
    totalRecords: {
      type: [String, Number],
      required: false,
      default: 0
    },
    totalFilteredRecords: {
      type: [String, Number],
      required: false,
      default: null
    },
    // configuration prop
    withSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    // data prop
    footerTable: {
      type: String,
      required: true,
      default: "Records"
    },

    optionsHeader: {
      type: Array,
      required: false,
      default: () => []
    },

    optionsRows: {
      type: [Array, Function],
      required: true,
      default: () => []
    },

    optionsSubRows: {
      type: Array,
      required: false,
      default: () => []
    },

    emptyText: {
      type: String,
      default: "No Records"
    },

    withTableMenu: {
      type: Boolean,
      default: false
    },

    inTable: {
      type: Boolean,
      required: false,
      default: false
    },

    classTable: {
      type: String,
      required: false,
      default: ""
    },

    rowActionButton:{
      type: Boolean,
      required: false,
      default: false
    },
    expandCollapseColumn:{
      type: Boolean,
      required: false,
      default: false
    },
    isExpanded:{
      type: Boolean,
      required: false,
      default: false     
    },
    hasOptionSendMessage: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultAllSelectedRows: {
      type: String,
      required: false,
      default: ""
    },
    showDefaultExportOptions: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFilteredExportOption: {
      type: Boolean,
      required: false,
      default: false
    },
    withTimeOut: {
      type: Boolean,
      required: false,
      default: true
    },

    // include pagination
    pagination: {
      type: Boolean,
      required: false,
      default: false
    },
    paginationCurrentPage: {
      type: Number,
      required: false,
      default: 1
    },
    paginationClass: {
      type: String,
      required: false,
      default: "ml-auto text-center md:text-right pagination-container"
    },
    paginationPageSizes: {
      type: Array,
      required: false,
      default: () => [25, 50, 100]
    },
    paginationPageSize: {
      type: Number,
      required: false,
      default: 25
    },
    paginationFooter: {
      type: String,
      required: false,
      default: 'total results',
    },
    hideFilteredExportOption: {
      type: Boolean,
      required: false,
      default: false
    },
    cellMaxWidth: {
      type: String,
      required: false,
      default: ""
    },
    totalRecordsFooter: {
      type: Number,
      required: false,
      default: -1
    },

    //Make rows draggable
    draggable:{
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {

    hideOptionFilteredExport() {
      return this.hideFilteredExportOption || (this.filteredRecordsCount == this.totalRecords) || this.filteredRecordsCount === 0
    },

    paginationPage: {
      get(){
        return this.paginationCurrentPage
      },
      set(p){
        this.paginationCurrentPage
      } 
    },

    paginationRange() {
      let start = 1
      let end = this.paginationCurrentPage * this.paginationPageSize

      if(this.paginationCurrentPage > 1) {
        start = ((this.paginationCurrentPage-1) * this.paginationPageSize) + 1
      }
      if(this.data.length < this.paginationPageSize){
        end = this.filteredRecordsCount;
      }
      if(end == 0) {
        return '0'
      }   
      return `${start}-${end}`
    },

    shouldDisplaySelectionDeleteAction() {
      const selectedRows = this.selectedRows.length ? this.selectedRows : this.allSelectedRows;
      return this.selectionDisplayDeleteActionResolver(selectedRows);
    },

    selectionCustomActionsToDisplay() {
      const selectedRows = this.selectedRows.length ? this.selectedRows : this.allSelectedRows;
      return this.selectionCustomActions
        .filter(action => {
          if (action.shouldDisplay instanceof Function) {
            return action.shouldDisplay(selectedRows);
          }
          return true;
        });
    },

    areAllRowsSelected() {
      return this.selectedRowsCount === this.data.length;
    },

    selectedRowsArray() {
      return this.data.filter(item => !!this.selectedRowsIDsMap[item.id]);
    },

    totalPages() {      
      return Math.ceil(this.filteredRecordsCount/this.pageSize)
    },

    selectedRowsCount() {
      //return this.selectedRowsArray.length;
      return Object.values(this.selectedRowsIDsMap).filter(v=>!!v).length
    },
    allSelectedRows(){
      return Object.values(this.selectedRowsIDsMap).filter(v=>!!v)
    },
    
    isExpandedComputed() {
      return this.showExpandCollapseColumn ?  "Expand Row Height" :   "Collapse Row Height" 
    },

    labelOptionSendMessage() {
      return this.selectedRowsCount > 0 ? "Message Associates" : `Message all ${this.filteredRecordsCount} matching Associates`
    }, 

    filterRecords() {
      return this.data.length;
    },
    
    canScrollToLeft() {
      return this.classScrollTable.includes("is-scrolling-right") || this.classScrollTable.includes("is-scrolling-middle");
    },

    canScrollToRight() {
      return this.classScrollTable.includes("is-scrolling-left") || this.classScrollTable.includes("is-scrolling-middle");
    },

    filterRecordsNew() {
      const count = this.data.length / 3
      return this.data.length <= 1 ? 1 : parseInt(count.toFixed(0));
    },

    getMaxWidth() {
      if (this.cellMaxWidth) return this.cellMaxWidth;
      return '500px';
    },
    
    classHeadByRoute(){
      if( !this.rowActionButton ) return ""

      const currentRoute = this.$router.currentRoute.name
      return currentRoute == 'StaffIndex' ? '':'flex justify-end'
    },
    classBodyByRoute(){
      if( !this.rowActionButton ) return ""

      const currentRoute = this.$router.currentRoute.name
      return currentRoute == 'StaffIndex' ? 'pr-1':'pr-3'
    },
    
    classContextMenuHeaderIcon(){
      return this.rowActionButton ? '-mr-0.75' : 'ml-0.75 mr-2'
    },
    isMultipleSelectedRecords(){
      return this.selectedRowsCount > 0;
    },
    filteredRecordsCount() {
      return this.totalFilteredRecords ?? this.totalRecords;
    },
    capitalizedSelectionEntity() {
      const entity = this.selectionEntityLabels[1].toLowerCase();
      return entity.charAt(0).toUpperCase() + entity.slice(1);
    },
    selectAllOnPageText() {
      const currentPageSelectedCount = this.selectedRowsArray.length;
      return this.selectedRowsCount > currentPageSelectedCount 
        ? `Also select the ${this.data.length} ${this.capitalizedSelectionEntity} on this page`
        : `Select the ${this.data.length} ${this.capitalizedSelectionEntity} on this page`;
    }
  },

  async mounted() {
    this.getContentScroll();
    this.scrollLeft = this.contentScroll.scrollLeft;
    this.haveScroll = this.contentScroll.classList.contains('is-scrolling-left') || this.contentScroll.classList.contains( 'is-scrolling-none' );
    this.classScrollTable = [ ...this.contentScroll.classList ];
    this.observer = this.observerHorizontalScrollbar();

    this.columns.forEach( column => {
      if ( column.fixed == 'right' ) {
        this.existFixedInTable = true;
      }
    });
    if(this.draggable){
      this.$nextTick(()=>{
        this.draggableSwitch(true)
      });
    }
    this.existFixedInTable = this.columns.some(column => column.fixed === 'right');
  },

  created() {
    this.loadChunk();
  },

  watch: {
    data(dataList) {
      if(!useMultiPageSelect){
        this.selectedRowsIDsMap = dataList.reduce(
          (acc, item) => (acc[item.id]=!!acc[item.id], acc), {}
        );
      }
    },

    data:{
      handler() {
        if(--this.sortableIsGrabbing < 1){
          this.visibleData = []
          this.loadChunk()
        }
      }
    },
    // Watch for changes in the visibleData length and trigger loading more data
    visibleData: {
      handler() {
        if (--this.sortableIsGrabbing < 1 && this.visibleData.length < this.data.length) {
          this.loadChunk();
        }
      },
    },

    selectedRowsIDsMap: {
      handler(rows) {
        let selectedIds = Object.keys(rows).filter(id => !!rows[id])
        this.$emit('selection-change', selectedIds, this.selectedRows);
      },
      deep: true,
      immediate: true
    },

    selectedRowsArray(selectedRows) {
      //this.$emit('selection-change', Object.values(this.selectedRowsIDsMap).filter(v=>!!v));
    },
    
    // deselects rows on all pages or 
    // sets selection to a provided object
    providedSelectedRows(rows){
      if(rows.constructor !== Object) {
        this.selectedRowsIDsMap = {}
        this.setSelectedValueForAllRows(false)
      } else {
        this.selectedRowsIDsMap = rows
      }
    },

    $route (to, from){
      
    },
    
    draggable(curr, bef){
      curr !== bef && this.draggableSwitch(curr)
    },

    defaultAllSelectedRows: {
      handler(newValue, oldValue) {
        if(newValue==="") return
        if (newValue === 'select_all') {
          this.handleSelectionCommand('select_all')
        }
        if(newValue === 'select_none') {
          this.handleSelectionCommand('select_none')
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      isSelectionDeleteModalOpen: false,
      isDropDownVisible: true,
      selectedRowsIDsMap: {},
      selectedRows: [],
      optionsContextHeader: this.optionsHeader,
      optionsContextRow: this.optionsRows,
      optionsContextSubRow: this.optionsSubRows,
      contentScroll: null,
      scrollLeft: 0,
      maxScroll: 10000,
      scrollRight: 0,
      haveScroll: false,
      componentKey: 0,
      haveTableMenuAction: this.withTableMenu,
      tableComponentKey: 0,
      classScrollTable: "",
      observer: null,
      existFixedInTable: false,
      showExpandCollapseColumn: this.expandCollapseColumn,

      sortableDrag: null,
      sortableOptions: null,
      sortableIsGrabbing: 0,
      sortableCaptureEvent: null,
      sortableTbody: null,

      forceUpdate: 0,
      isExpandedCol: this.expandCollapseColumn,
      canSendMessage: this.hasOptionSendMessage,
      scrollMaxWidth: [],
      visibleData: [],
      chunkSize: 50, // Number of rows to display at a time
      loading: false,
      canvasChar: {}
    }
  },
  
  methods: {
    handleExport(type) {
      if(!this.visibleData.length && this.totalRecords === 0) {
        this.displayUserNotification({
          title: "Export",
          type: "info",
          message: `Table has no data to export.`,
        });
        return;
      }
      this.$emit('export-data', type);
    },
    
    rowSelection(row, e){
      this.$set(this.selectedRowsIDsMap, row.id, e);
      if (e) {
        this.selectedRows.push(row)
      } else {
        this.selectedRows = this.selectedRows.filter(selected => selected.id !== row.id)
      }
    },

    loadChunk() {
      if(this.withTimeOut && !this.sortableIsGrabbing){
        this.loading = true;
        // Simulating an asynchronous data loading
        setTimeout(() => {
          const startIndex = this.visibleData.length;
          const endIndex = startIndex + this.chunkSize;
          this.visibleData = this.visibleData.concat(this.data.slice(startIndex, endIndex));

          this.loading = false;
        }, 500);
      }else{
          const startIndex = this.visibleData.length;
          const endIndex = startIndex + this.chunkSize;
          this.visibleData = this.visibleData.concat(this.data.slice(startIndex, endIndex));
      }
    },
    handleCustomMenuClick() {
        this.isDropDownVisible = true
    },

    getOptionsContextRow(row){
      return this.optionsContextRow.filter(item => !item.condition || item.condition(row))
    },
    
    async getWidth(colIndex, rowIndex, row, column){
      
      if(!Array.isArray(column.toolTipValues)){
        column.toolTipValues = []
      }

      const { width, col, toolTipValues } = column

      const rowValidated = (Array.isArray(row[col])) ? row[col][0] : row[col]

      const id = (row?.id ? row.id : rowValidated) + colIndex

      let tryGetElementWidth = async (remainingAttempts)=>{
        const timestamp = +(new Date)

        if(toolTipValues[rowIndex] && toolTipValues[rowIndex].timestamp + 100 > timestamp){
          return toolTipValues[rowIndex].value
        }

        const element = document.getElementById(id);
        
        if(element){
  
          const computedStyle = window.getComputedStyle(element);

          const filterWidthProperty = this.scrollMaxWidth.find(item => item.col === col)
          const widthText = filterWidthProperty?.widthText ?? element.scrollWidth ?? width;
  
          const existingIndex = this.scrollMaxWidth.findIndex(item => item.col === col);
  
          if (existingIndex !== -1) {
            // If the property exists, compare and update the value if it's greater
            if (this.scrollMaxWidth[existingIndex].widthText < widthText) {
              this.scrollMaxWidth[existingIndex].widthText = widthText;
            }
          } else {
            // If the property doesn't exist, add a new object to the array
            this.scrollMaxWidth.push({ col, widthText });
          }
  
          const numCharacters = this.calculateCharactersForWidth(widthText, rowValidated, computedStyle, element);
          toolTipValues[rowIndex] = {
            value: numCharacters,
            timestamp
          }
          return numCharacters
        }
        if(remainingAttempts--){
          await new Promise(r => setTimeout(r, 100))
          return await tryGetElementWidth(remainingAttempts)
        }
        
        toolTipValues[rowIndex] = {
          value: 0,
          timestamp
        }
        return 0
      }
      return await tryGetElementWidth(4)
    },

    calculateCharactersForWidth(desiredWidth, text, computedStyle) {
      if (!text) return 0;

      if (!this.canvasChar.canvas) {
        this.canvasChar.canvas = document.createElement("canvas");
        this.canvasChar.context = this.canvasChar.canvas.getContext("2d");
      }

      const context = this.canvasChar.context;
      context.font = this.getCanvasFont(computedStyle);

      let textLength = 0;
      let textWidth = 0;

      for (let i = 0; i < text.length; i++) {
        const char = text[i];
        const charWidth = context.measureText(char).width;

        if (textWidth + charWidth > desiredWidth) {
          break;
        }

        textWidth += charWidth;
        textLength++;
      }

      return textLength;
    },

    getCanvasFont(computedStyle = {}) {
      const fontWeight = computedStyle.fontWeight || '400';
      const fontSize =  computedStyle.fontSize || '14px';
      const fontFamily = computedStyle.fontFamily || 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
      return `${fontWeight} ${fontSize} ${fontFamily}`;
    },
    async handleSelectionCommand(command) {
      switch(command) {
        case 'select_all': 
          let multiPageSelection = await this.selectAllHandler()
          if(multiPageSelection){
            this.selectedRows = this.allRecordsSelected
            this.selectedRowsIDsMap = multiPageSelection
          }else{
            this.setSelectedValueForAllRows(true);
          }
          break;
        case 'select_all_on_page':
          this.setSelectedValueForCurrentPage(true);
          break;
        case 'select_none': this.setSelectedValueForAllRows(false); break;
        case 'delete_selected': this.handleDeleteSelected(); break;
        case 'export_selected': this.handleExportSelected(); break;
        default:
          const foundAction = this.selectionCustomActionsToDisplay.find((action) => action.command === command);
          if (!foundAction) throw new Error('Invalid command ${command} for selection action');
          foundAction.handler(this.selectedRowsArray, this.selectRowsByCb);
          break;
      }
    },

    selectRowsByCb(selectResolver) {
      this.selectedRowsIDsMap = this.data.reduce((acc, item) => {
        const isDisabled = this.selectionRowDisabledResolver(item);
        acc[item.id]= (isDisabled)? false: !!selectResolver(item);
        return acc;
      },
      {});
    },

    setSelectedValueForAllRows(value) {
      this.selectedRowsIDsMap = this.data.reduce((acc, item) => {
        const isDisabled = this.selectionRowDisabledResolver(item);
        acc[item.id] = (isDisabled)? false: !!value;
        return acc;
      },
      {});

      if (value) {
        this.selectedRows = this.selectedRows.concat(this.data).filter((value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
        );
      } else {
        this.selectedRows = []
      }
    },
    setSelectedValueForCurrentPage(value) {
       this.data.forEach(item => {
       const isDisabled = this.selectionRowDisabledResolver(item);
       if (!isDisabled) {
        this.$set(this.selectedRowsIDsMap, item.id, !!value);
        if (!!value) {
          const selectedRowExists = this.selectedRows.find(row => row.id === item.id)
          if(!selectedRowExists) {
            this.selectedRows.push(item)
          }
        } else {
          this.selectedRows = this.selectedRows.filter(selected => selected.id !== item.id)
        }
      }
     });
   },

    handleDeleteSelected() {
      this.isSelectionDeleteModalOpen = true;
      this.$emit('before-delete-action', this.selectedRowsArray)
    },

    handleExportSelected() {
      const type = 'selected'
      this.$emit('export-data', type);
    },

    async commitSelectionDelete() {
      this.isSelectionDeleteModalOpen = false;
      await this.selectionDeleteHandler(this.selectedRowsArray);
    },

    getValueOfRowProp(prop='', row='') {
      const nested = prop.split('.');

      const isStatus = prop.toLowerCase().includes('status');

      let val = row;

      try {
        for (const nestedProp of nested) {
          val = val[nestedProp];
        }

        // Handle display parsed Counseling status
        if (isStatus && (val?.includes('_') || val?.toLowerCase().includes('signed'))) {
          return parseCounselingStatusValueToLabel(val);
        }

        return val;
      }
      catch {
        return null;
      }
    },

    isStatusField(field='') {
      return field.toLowerCase().includes('status');
    },

    setClassStatus(status='') {
      status = status.toLowerCase();

      return {
        'bg-red-500 text-red-600' : (
          !status ||
          status.includes('inactive') ||
          status.includes('refused') || // Counseling Status
          (status == 'lapsed trial' || status == 'churned') // Tenant Status
        ),
        'bg-purple-500 text-purple-600' : (
          status.includes('onboarding') ||
          status.includes('pending') // Counseling status
        ),
        'bg-green-500 text-green-600' : (
          status == 'active' ||
          status.includes('signed') // Counseling Status
        ),
        'bg-yellow-500 text-yellow-900' : (
          status.includes('not') || // Counseling Status
          status == 'trial' // Tenant Status
        ),
        'bg-orange-500 text-orange-600': (
          status == 'unknown' // Tenant Status
        )
      };
    },

    observerHorizontalScrollbar() {
      const observer = new MutationObserver(( mutationList, observer ) => {
        const contentScroll = mutationList[0].target;
        this.classScrollTable = [ ...contentScroll.classList ];
      })

      observer.observe(this.contentScroll, { attributes: true });

      return observer;
    },
    
    swipeLeft() {
      this.scrollTo( this.contentScroll, -300, 800 );
      this.scrollLeft = this.contentScroll.scrollLeft - 300;
    },

    async swipeRight() {
      await this.scrollTo( this.contentScroll, 300, 800 );
      this.scrollLeft = this.contentScroll.scrollLeft + 300;
    },

    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
        
      this.maxScroll = element.scrollWidth;
      this.scrollRight = element.clientWidth + scrollPos;
        
      const startTime = "now" in window.performance ? performance.now() : new Date().getTime();
      const vm = this;
      function scroll( timestamp ) {
        
        const timeElapsed = timestamp - startTime;
        
        const progress = Math.min( timeElapsed / duration, 1 );

        element.scrollLeft = scrollPos + scrollPixels * progress;

        if ( timeElapsed < duration ) {
          
          window.requestAnimationFrame( scroll );
        } else {
          vm.scrollRight = element.clientWidth + element.scrollLeft;
          return;
        }
      }

      window.requestAnimationFrame( scroll );
    },

    getContentScroll() {
      const table = this.$refs['table'];
      const { bodyWrapper } = table.$refs;
      this.contentScroll = bodyWrapper
    },

    sortChange(e) {
      this.$emit('sort-change', e);
    },

    paginationPageSizeChange(s) {
      this.$emit('pagination-page-size-change', s);
    },

    paginationPageChange(p) {
      this.$emit('pagination-page-change', p);
    },

    handleCommand( command ) {
     this.isDropDownVisible = false;
     
      if(command !== undefined)
      {
        this.$emit( "click-menu", command );
      }
    },

    handleColumn() {
      this.showExpandCollapseColumn = !this.showExpandCollapseColumn;
    },

    handleSendMessage() {
      this.$emit("click-menu", { data:null, action: "message" });
    },

    isRowTextBeingSelected(event){
      let range;
      if (document.caretRangeFromPoint) {
          range = document.caretRangeFromPoint(event.clientX, event.clientY);
      } else if (document.caretPositionFromPoint) {  // Para navegadores más antiguos
          const pos = document.caretPositionFromPoint(event.clientX, event.clientY);
          range = document.createRange();
          range.setStart(pos.offsetNode, pos.offset);
          range.setEnd(pos.offsetNode, pos.offset);
      }
      if (range && range.startContainer.nodeType === Node.TEXT_NODE) {
        return true
      }else{
        return false
      }
    },

    findAncestorByAttribute(element, attributeName, attributeValue) {
      while (element) {
        if (element.hasAttribute(attributeName) && element.getAttribute(attributeName) === attributeValue) {
          return element;
        }
        element = element.parentElement;
      }
      return null;
    },
    
    draggableSwitch(enabled){
      if(enabled){
        const _this = this
        if(this.sortableDrag){
          this.sortableDrag.option("disabled") && this.sortableDrag.option("disabled", false);
        }else{
          const tbody = document.querySelector('.el-table__body-wrapper tbody')
          if(!this.sortableOptions){
            this.sortableOptions = {
              animation: 250,
              onChange:(event)=>{
                _this.sortableIsGrabbing = 4
              },
              onEnd:(event) => {
                if(event.oldIndex !== event.newIndex){
                  const [ popElement ] = _this.data.splice(event.oldIndex, 1)
                  _this.data.splice(event.newIndex, 0, popElement)
                  _this.$emit('row-dragged', event)
                }
                _this.sortableIsGrabbing--
                _this.forceUpdate++
              },
              easing: "cubic-bezier(.34,.1,.14,1.01)",
              draggable: '.table-row'
            }
          }
          this.sortableDrag = Sortable.create(tbody, this.sortableOptions);

          if(!this.sortableCaptureEvent){
            this.sortableCaptureEvent = (event)=>{
              const rowSelection = _this.isRowTextBeingSelected(event)
              if(rowSelection){
                const tr = this.findAncestorByAttribute(event.target, 'draggable', "true")
                if(tr){
                  tr.removeAttribute('draggable')
                }
                if(_this.sortableDrag.option("disabled")){
                  _this.sortableDrag.option("disabled", true)
                }
                event.stopImmediatePropagation()
              }else{
                _this.sortableDrag.option("disabled") && _this.sortableDrag.option("disabled", false)
              }
            }
            tbody.addEventListener('mousedown', _this.sortableCaptureEvent, true);
            this.sortableTbody = tbody
          }
        }
        
      }else{
        this.sortableDrag && !this.sortableDrag.option("disabled") && this.sortableDrag.option("disabled", true);
        (this.sortableTbody && this.sortableCaptureEvent) && (this.sortableTbody.removeEventListener('mousedown', this.sortableCaptureEvent))
      }
    }
  },

  beforeDestroy() {
    if(this.observer) this.observer.disconnect();
    this.draggableSwitch(false)
  }
}
</script>

<style lang="scss" scoped>

.dropdown__item--menu {
  padding: 0;
}

.dropdown__item--menu:not(.is-disabled):hover {
  background-color: transparent;
  cursor: default;
  color: #606266;
}

.menu__item:hover {
  background-color: #e6f0f8;
  color: #3586c9;
}
.border-rounded {
  border-radius: .5rem !important;
}

.contextmenu__title {
  font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding-bottom: 5px;
}

.fix-height {
  height: 25px;
}

.disabled {
  cursor: default;
  color: #bbb;
  pointer-events: none;
}

.custom-table {
  border: 1px solid rgba(226, 232, 240, 1);
}

.custom-table--card {
  border: none;
}

.align-left {
  text-align: left;
}

.custom-blue-color {
  color : #0268BC
}

.truncate {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}
</style>

<style>
@media screen and (max-width: 768px) {
  .pagination-container > .el-pagination__sizes > .el-select {
    width: auto !important;
  }
  .pagination-container > .el-pagination__sizes {
    display: block !important;
    margin: 0 auto 1.5rem !important;
  }
}
</style>
<style lang="scss">
.custom-table-draggable 
.el-table__body-wrapper
tr > td:not(td:has( ~ * + .is-hidden)) .cell > span:after{
  @apply absolute right-0 text-sm;
  margin-top: 1px;
  content: '\ebae';
  font-family: "unicons";
}
</style>